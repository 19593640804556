import chroma from "chroma-js"
import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import Linkify from "react-linkify"
import styled from "styled-components"
import { ButtonPrimary as BP } from "../core/Buttons"
import TenantLogo from "../core/TenantLogo"
import { segmentAction } from "../hooks/useSegmentTrack"
import IconMessaging from "../icons/IconMessaging"
import { Content as C } from "./layout/Content"
import PageFollowShare from "./PageFollowShare"
import SupportWithCrypto from "./support/SupportCrypto"
import { getSparkleNumber } from "./utils"

const Grid = styled(C)`
  display: grid !important;
  gap: 10px;
  height: 100%;
  grid-template-areas: ${({ hasIntro }) => {
    return hasIntro
      ? `
    "logo social social"
    "title title support"
    "intro intro support"
  `
      : `
    "logo social social"
    "title title support"
    "title title support"
  `
  }};
  grid-template-rows: 150px 1fr auto;
  grid-template-columns: 150px auto auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 800px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
    gap: 0px;
    grid-template-areas: ${({ hasIntro }) => {
      return hasIntro
        ? `
      "logo"
      "title"
      "intro"
      "support"
  `
        : `
      "logo"
      "title"
      "title"
      "support"
  `
    }};
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
  }
`

const SupportButton = styled(BP)`
  box-shadow: none;
  min-width: 175px;
  font-size: 1.05rem;
  padding: 0.9em 1.6em;
  line-height: 1;
  @media screen and (max-width: 800px) {
    font-size: 1.05rem;
  }
`

const SocialArea = styled.div`
  grid-area: social;
  align-self: start;
  justify-self: end;
  display: flex;
  align-items: center;
  @media screen and (max-width: 800px) {
    /* Let's minimize distractions for now... could add back if we need it... allow to just be in the footer for now */
    display: none;
    /* Turn off headers just use btns */
    margin-top: 0.5rem;
    align-self: center;
    justify-self: center;
  }
`

// Intro +/or phone
const IntroArea = styled.div`
  border-radius: 22px;
  font-size: 0.95rem;
  align-self: start;
  line-height: 1.45;
  align-items: center;
  .intro {
    a {
      color: var(--accent);
      font-weight: bold;
    }
    width: min(80vw, 525px);
    @media screen and (max-width: 800px) {
      margin: 0 auto;
    }
  }
  span > a {
    color: var(--accent);
    padding-bottom: 1px;
    border-bottom: 1px solid var(--accent);
  }
  a:hover {
    text-decoration: none;
  }
  a:focus {
    color: #000;
  }
  @media screen and (max-width: 800px) {
    font-size: 0.825rem;
    line-height: 1.4;
    justify-content: center;
    margin-top: 0.5rem;
    text-align: center;
    > div {
      /* Will center */
      margin: auto;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 0rem;
  }
`
const LogoArea = styled.div`
  position: relative;
  grid-area: logo;
  height: 150px;
  width: 150px;
  border-radius: 150px;
  box-shadow: 0 0 0 6px #faf9f6,
    0 0 0px 10px ${({ theme }) => chroma(theme.colors.accent).alpha(0.4)};
  overflow: hidden;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    height: 115px;
    width: 115px;
    margin: auto;
    margin-bottom: 15px;
  }
`
const SupportArea = styled.div`
  justify-self: end;
  align-self: end;
  @media screen and (max-width: 800px) {
    margin-top: 1rem;
    justify-self: center;
    align-self: center;
  }
`

const Title = styled.h1`
  font-size: ${({ titleLength }) => {
    if (titleLength > 40) return "44px"
    if (titleLength > 30) return "50px"
    if (titleLength > 20) return "44px"
    return "48px"
  }};
  font-weight: bold;
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`

const SparkleNumber = styled.p`
  ${({ hasIntroText }) => {
    if (hasIntroText)
      return `
      margin-top: 15px;
      @media screen and (max-width: 600px) {
        margin-top: 8px;
      }
    `
  }}
`

const formatIntroText = (intro) => {
  const words = intro.trim().split(" ")
  const len = words.length
  let pivot = words.length - 1
  let count = 0
  const max = 20 // keep last 20 words together
  for (let i = len - 1; i > -1; i--) {
    const length = words[i].length
    count += length
    if (count <= max) pivot = i
    else break
  }
  return words
    .map((word, i) => {
      if (i === len - 1) return word
      if (i >= pivot) return `${word}\u00A0`
      return `${word} `
    })
    .join("")
}

const PageHeader = ({
  tenantInfo,
  tenantInfo: {
    tenantName,
    tenantId,
    tenantContributionProduct,
    tenantIntro = "",
    tenantSparkleNumberTextOverride = "",
  } = {},
  socialLinks,
  tenantUrl,
}) => {
  const sparkleNumber = getSparkleNumber({ tenantInfo })
  const hasSupport = tenantContributionProduct && tenantContributionProduct.id

  const [hasCrypto, setHasCrypto] = useState(false)
  const introText = formatIntroText(tenantIntro)
  const hasIntro = !!sparkleNumber || !!introText

  useEffect(() => {
    // janky setting of hasCrypto 0:]

    const formattedTenantUrl = tenantUrl.toLowerCase()

    if (
      formattedTenantUrl === "wesparkleqa" ||
      formattedTenantUrl === "veecon"
    ) {
      // console.log(tenantUrl)
      setHasCrypto(true)
    }
    // console.log("boop")
  }, [tenantUrl])

  return (
    <header
      style={{
        borderBottom: "1px solid rgba(0,0,0,.1)",
        background: "#FAF9F6",
      }}
    >
      <Grid hasIntro={hasIntro}>
        <LogoArea>
          <TenantLogo tenantInfo={tenantInfo} />
        </LogoArea>
        <SocialArea>
          <PageFollowShare
            socialLinks={socialLinks}
            tenantUrl={tenantUrl}
            tenantName={tenantInfo?.tenantName}
            tenantImageUrl={tenantInfo?.tenantImageUrl}
          />
        </SocialArea>
        <Title
          titleLength={(tenantName || "temp").length}
          style={{ gridArea: "title" }}
        >
          {tenantName || "\u00A0"}
        </Title>
        {hasIntro && (
          <IntroArea style={{ gridArea: "intro" }}>
            {introText && (
              // TODO: At some point track links through here too with segment
              <Linkify>
                <p className={"intro"}>{introText}</p>
              </Linkify>
            )}
            {sparkleNumber && (
              <SparkleNumber hasIntroText={!!introText}>
                <a
                  onClick={() => {
                    segmentAction.track("Sparkles Number Click")
                  }}
                  href={`sms://${sparkleNumber.replace(/[()\s-]/g, "")}`}
                  title={`Text ${tenantName}`}
                >
                  <IconMessaging
                    className={"accent-fill"}
                    style={{
                      position: "relative",
                      top: ".12em",
                      width: "1.25em",
                      height: "auto",
                    }}
                  />
                </a>
                <span style={{ marginLeft: 4 }}>
                  Text "hi" to{" "}
                  <a
                    onClick={() => {
                      segmentAction.track("Sparkles Number Click")
                    }}
                    style={{ fontWeight: "bold" }}
                    href={`sms://${sparkleNumber.replace(/[()\s-]/g, "")}`}
                    title={`Text ${tenantName}`}
                  >
                    {sparkleNumber.replace("+1 ", "").replace(" ", "\u2009")}
                  </a>{" "}
                  {(tenantSparkleNumberTextOverride &&
                    tenantSparkleNumberTextOverride) ||
                    "to connect"}
                </span>
              </SparkleNumber>
            )}
          </IntroArea>
        )}

        <SupportArea
          style={{
            gridArea: "support",
          }}
        >
          {hasSupport && hasCrypto && <SupportWithCrypto />}
          {hasSupport && !hasCrypto && (
            <SupportButton
              onClick={() => {
                segmentAction.track("Sparkles Support Click")
                navigate(`/${tenantUrl}?support`)
              }}
            >
              {tenantContributionProduct.name || "Support"}
            </SupportButton>
          )}
        </SupportArea>
      </Grid>
    </header>
  )
}

export default PageHeader
