import { useState, useEffect, useRef } from "react"

const getWinSize = () => {
  const browser = typeof window !== "undefined"
  if (!browser) return { width: 1, height: 1 }
  return {
    width: window.innerWidth || 1,
    height: window.innerHeight || 1,
  }
}

const useWinDim = ({ width = true, height = true }) => {
  const [dim, setDim] = useState(() => {
    const initDim = {}
    if (width) initDim.width = 1
    if (height) initDim.height = 1
    return initDim
  })
  const firstLoad = useRef(true)
  useEffect(() => {
    const setWinSize = () => {
      const nextDim = getWinSize()
      const updateDim = {}
      let update = false
      if (width && Math.round(dim.width) !== Math.round(nextDim.width)) {
        updateDim.width = Math.round(nextDim.width)
        update = true
      }
      if (height && Math.round(dim.height) !== Math.round(nextDim.height)) {
        updateDim.height = Math.round(nextDim.height)
        update = true
      }
      if (update)
        setDim((prev) => {
          return { ...prev, ...updateDim }
        })
    }
    if (firstLoad && firstLoad.current) {
      // Need to get the dimensions without a resize happening
      firstLoad.current = false
      setWinSize()
    }
    window.addEventListener("resize", setWinSize)
    return () => {
      window.removeEventListener("resize", setWinSize)
    }
  }, [setDim, dim, width, height])
  return dim
}

export default useWinDim
