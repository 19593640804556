import React from "react"
import styled, { keyframes } from "styled-components"

const animation = keyframes`
  from { opacity: 1}
  to {
    opacity: 0
  }
`

const Circle1 = styled.circle`
  animation: 1s 0.2s ${animation} infinite both;
`
const Circle2 = styled.circle`
  animation: 1s 0.35s ${animation} infinite both;
`
const Circle3 = styled.circle`
  animation: 1s 0.5s ${animation} infinite both;
`

// TODO: Switch to divs over animated svg
const IconLoaderDots = ({ style = {}, ...props }) => {
  const initStyle = {
    fill: "#fff",
    stroke: "none",
    height: "8px",
    overflow: "visible",
    width: "auto",
  }
  return (
    <svg {...props} viewBox="0 0 52 17" style={{ ...initStyle, ...style }}>
      <Circle1 cx="6" cy="6" r="6" />
      <Circle2 cx="26" cy="6" r="6" />
      <Circle3 cx="46" cy="6" r="6" />
    </svg>
  )
}

export default IconLoaderDots
