import React from "react"
import IconLoaderDots from "../icons/IconLoaderDots"

const ButtonLoaderWithText = ({ isProcessing }) => {
  return (
    <IconLoaderDots
      // Animation to slide in loader
      style={{
        marginLeft: isProcessing ? 10 : 0,
        width: isProcessing ? "auto" : 0,
        transition: ".2s",
        opacity: isProcessing ? 1 : 0,
        fill: "#fff",
        height: isProcessing ? "8px" : 0,
        overflow: isProcessing ? "visible" : "hidden",
      }}
    />
  )
}

export default ButtonLoaderWithText
