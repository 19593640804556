import { useEffect, useState } from "react"
import axios from "axios"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useBookingInfoPending = ({
  bookingId, // customer specific id TODO: change the name here to prevent confusion ie: customerBookingId
}) => {
  const [bookingInfo, setBookingInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (bookingId) {
      setLoading(true)
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`GetPendingPaymentBooking?code=${apiCode}`, {
          bookingId,
        })
        .then((response) => {
          if (response && response.data) {
            setBookingInfo(response.data)
          }
        })
        .catch((error) => {
          console.log(error)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [bookingId])
  return { bookingInfo, loading, error }
}

export default useBookingInfoPending
