import React from "react"

const IconError = (props) => {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M87.9,11.4H12.1c-5.3,0-9.6,4.3-9.6,9.6V79c0,5.3,4.3,9.6,9.6,9.6h75.8c5.3,0,9.6-4.3,9.6-9.6V21 C97.5,15.7,93.2,11.4,87.9,11.4z M66.9,20.1h14.9c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5H66.9c-1.4,0-2.5-1.1-2.5-2.5 C64.4,21.2,65.5,20.1,66.9,20.1z M36.8,19.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C33.4,20.7,34.9,19.1,36.8,19.1z M26.7,19.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C23.3,20.7,24.9,19.1,26.7,19.1z M16.7,19.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4 C13.3,20.7,14.8,19.1,16.7,19.1z M91.5,79c0,2-1.6,3.6-3.6,3.6H12.1c-2,0-3.6-1.6-3.6-3.6V33.8h83.1V79z"></path>
      <circle cx="38.6" cy="49.3" r="5.5"></circle>
      <circle cx="61.4" cy="49.3" r="5.5"></circle>
      <path d="M37,67.9c-0.9,1.4-0.5,3.3,0.9,4.3c1.4,0.9,3.3,0.5,4.3-0.9c1.5-2.3,4.5-3.8,7.9-3.8c3.4,0,6.4,1.4,7.9,3.8 c0.9,1.4,2.8,1.8,4.3,0.9c1.4-0.9,1.8-2.8,0.9-4.3c-2.6-4.1-7.6-6.6-13.1-6.6S39.6,63.8,37,67.9z"></path>
    </svg>
  )
}

export default IconError
