import React from "react"

const IconGroup = ({ group, ...props }) => {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      {group ? (
        <>
          <path d="M79.1,49.9c-6,0-10.9-4.9-10.9-10.9s4.9-10.9,10.9-10.9C85.1,28.1,90,33,90,39S85.1,49.9,79.1,49.9z M79.1,33.1  c-3.3,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9S82.4,33.1,79.1,33.1z"></path>
          <path d="M20.9,49.9C14.9,49.9,10,45,10,39s4.9-10.9,10.9-10.9S31.8,33,31.8,39S26.9,49.9,20.9,49.9z M20.9,33.1  c-3.3,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9S24.2,33.1,20.9,33.1z"></path>
          <path d="M79.2,72c-4,0-6.7-0.2-8.7-0.5l-2.1-0.4v-2.5c0-4-1.3-8-3.7-11.6l-0.8-1.2l0.7-1.3c2.9-5.8,8.6-9.5,14.5-9.5 c8.9,0,16.5,8,16.6,17.5C95.7,72,86.9,72,79.2,72z M73.3,66.8c1.5,0.1,3.4,0.2,5.9,0.2c10.1,0,11.5-1,11.5-4.5 C90.6,55.9,85.2,50,79.1,50c-3.7,0-7.2,2.1-9.4,5.5C71.8,59,73,62.9,73.3,66.8z"></path>
          <path d="M20.9,72c-7.7,0-16.5,0-16.5-9.5C4.4,53,12,45,20.9,45c5.9,0,11.7,3.7,14.5,9.5l0.7,1.3L35.3,57 c-1.7,2.5-3.7,6.5-3.6,11.5l0,2.5l-2.1,0.4C27.6,71.8,24.9,72,20.9,72z M20.9,50c-6.1,0-11.5,5.8-11.5,12.5 c0,3.5,1.4,4.5,11.5,4.5c2.5,0,4.3-0.1,5.8-0.2c0.2-3.9,1.4-7.7,3.6-11.3C28.1,52.1,24.6,50,20.9,50z"></path>
          <path d="M50,81.5c-11.5,0-23.4,0-23.4-12.9c0-13.5,10.7-24.9,23.4-24.9c12.7,0,23.4,11.4,23.4,24.9C73.4,81.5,61.5,81.5,50,81.5z  M50,48.7c-9.8,0-18.4,9.3-18.4,19.9c0,6.4,3.4,7.9,18.4,7.9c15,0,18.4-1.5,18.4-7.9C68.4,58,59.8,48.7,50,48.7z"></path>
          <path d="M50,48.7c-8.3,0-15.1-6.8-15.1-15.1S41.7,18.5,50,18.5s15.1,6.8,15.1,15.1S58.3,48.7,50,48.7z M50,23.5 c-5.6,0-10.1,4.5-10.1,10.1S44.4,43.7,50,43.7s10.1-4.5,10.1-10.1S55.6,23.5,50,23.5z"></path>
        </>
      ) : (
        <>
          <path d="M50,81.5c-11.5,0-23.4,0-23.4-12.9c0-13.5,10.7-24.9,23.4-24.9c12.7,0,23.4,11.4,23.4,24.9C73.4,81.5,61.5,81.5,50,81.5z  M50,48.7c-9.8,0-18.4,9.3-18.4,19.9c0,6.4,3.4,7.9,18.4,7.9c15,0,18.4-1.5,18.4-7.9C68.4,58,59.8,48.7,50,48.7z"></path>
          <path d="M50,48.7c-8.3,0-15.1-6.8-15.1-15.1S41.7,18.5,50,18.5s15.1,6.8,15.1,15.1S58.3,48.7,50,48.7z M50,23.5 c-5.6,0-10.1,4.5-10.1,10.1S44.4,43.7,50,43.7s10.1-4.5,10.1-10.1S55.6,23.5,50,23.5z"></path>
        </>
      )}
    </svg>
  )
}

export default IconGroup
