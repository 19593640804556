import React from "react"
import styled from "styled-components"
import IconCalendar from "../../icons/IconCalendar"
import { ButtonPrimary } from "../../core/Buttons"
import BookingsText from "./BookingsText"
import { containerStyling } from "../../core/utils"

const Container = styled.div`
  ${containerStyling}
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px 8px 11px;
  width: 100%;
  cursor: pointer;
  /* TODO: Double check no hover */
  :hover {
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
    transform: scale(1.025) translateZ(0);
  }
  h3 {
    font-weight: bold;
    font-size: 0.935rem;
    margin-bottom: 4px;
  }
  p {
    font-weight: normal;
    font-size: 0.85rem;
    line-height: 1.35;
  }
  .price {
    display: block;
    font-weight: bold;
    margin-left: 0;
  }
  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  strong {
    font-size: 1.1em;
  }
`

const Circle = styled.div`
  margin: auto;
  opacity: 0.65;
  display: flex;
  color: var(--accent);
  border-radius: 100%;
  border: 3px solid #ccc;
`

const BookingsCard = ({ booking, onClick }) => {
  return (
    <Container onClick={onClick}>
      <div
        style={{
          height: 130,
          width: "100%",
          background: "#f7f7f7",
          borderRadius: "18px",
          display: "flex",
          position: "relative",
        }}
      >
        <Circle style={{ width: 100, height: 100 }}>
          <IconCalendar
            style={{
              transform: "translateY(-3%) translateX(-1%) translateZ(0)", // quick adjustments for top-heavy icon
              width: "56px",
              height: "auto",
              marginBottom: ".5rem",
              margin: "auto",
            }}
          />
        </Circle>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: ".7rem 10px",
          width: "100%",
          position: "relative",
        }}
      >
        <BookingsText booking={booking} />
      </div>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          width: "100%",
        }}
      >
        <ButtonPrimary
          as={"span"}
          style={{
            fontSize: ".95rem",
            flex: 1,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Book</span>
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export default BookingsCard
