import React, { useState, useRef } from "react"
import IconShare from "../../icons/IconShare"
import { ButtonSuperSimple } from "../../core/Buttons"
import Share from "../../core/Share"
import { segmentAction } from "../../hooks/useSegmentTrack"

const ProductShare = ({ item, item: { name, imageUrl } = {}, tenantName }) => {
  const [showShare, setShowShare] = useState(false)
  const socialRef = useRef(null)
  return (
    <ButtonSuperSimple
      onClick={() => setShowShare((v) => !v)}
      ref={socialRef}
      style={{
        fontSize: 13,
        fontWeight: "bold",
        position: "absolute",
        left: 6,
        top: 8,
        border: "none",
        padding: "8px 14px",
      }}
    >
      Share{" "}
      <IconShare style={{ width: 16, height: "auto", fill: "currentColor" }} />
      {/* Popover */}
      <Share
        onOptClick={(value) => {
          segmentAction.track(
            "Sparkles Product Share Click",
            {
              value,
            },
            { product: item }
          )
        }}
        target={socialRef && socialRef.current}
        name={`${name} • ${tenantName}`}
        media={imageUrl}
        // url if not supplied will use current url
        show={showShare}
        setShow={setShowShare}
      />
    </ButtonSuperSimple>
  )
}

export default ProductShare
