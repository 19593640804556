import React from "react"
import { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import useKeyPress from "../hooks/useKeypress"
import { PanelBooking, PanelProduct, PanelPrompt } from "./ModalsPanels"
import { Bg, Close, IconCircle, IconContainer, Back } from "./ModalsShared"
import { ButtonPrimary, ButtonGray } from "./Buttons"
import useIsBrowser from "../hooks/useIsBrowser"
import IconLoaderCircle from "../icons/IconLoaderCircle"
import AnimateHeight from "react-animate-height"

const hasButton = (btn) => !!(btn && btn.children && btn.onClick)

// TODO: Allow to "swipe" away in mobile view
// TODO: Separate into many modals?
const Modals = ({
  onClose: givenOnClose,
  onClick: givenOnClick,
  onBack: givenOnBack,
  backText,
  loading, // only for prompt option for now
  hide = false, // used to keep timekit element around
  icon,
  status,
  type = "prompt",
  primary, // -> object
  secondary, // -> object
  onlyCenterModals, // Do not want "mobile view" to tether to bottom of page
  children,
  ...props
}) => {
  // During loading we don't want to allow you to click out till done loading
  const onClose = loading ? undefined : givenOnClose
  const onClick = loading ? undefined : givenOnClick
  const onBack = loading ? undefined : givenOnBack

  const ref = useRef(null)
  const isBrowser = useIsBrowser()
  // Give modal focus... an a11y thing for easier navigating in a modal...
  useEffect(() => {
    if (isBrowser && ref && ref.current && !hide) {
      // ref.current.focus()
    }
  }, [ref, isBrowser, hide])
  useKeyPress("Escape", onClose)
  // No scrolling on main content with modal
  useEffect(() => {
    if (document && !hide) {
      document.documentElement.style.overflow = "hidden"
    }
    return () => {
      if (document) {
        document.documentElement.style.overflow = ""
      }
    }
  }, [hide])
  if (!isBrowser) return null
  switch (type) {
    case "booking":
      return ReactDOM.createPortal(
        <>
          <Bg
            style={
              hide
                ? { opacity: 0, pointerEvents: "none", visibility: "hidden" }
                : {}
            }
            onClick={onClick}
            {...props}
          >
            <PanelBooking
              onlyCenterModals={onlyCenterModals}
              tabIndex={-1}
              ref={ref}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {onClose && <Close onClick={onClose} />}
              {onBack && <Back onClick={onBack} text={backText} />}
              {children}
            </PanelBooking>
          </Bg>
        </>,
        document.body
      )
    case "product": {
      const hasPrimary = hasButton(primary)
      const hasSecondary = hasButton(secondary)
      const hasActions = hasPrimary || hasSecondary
      return ReactDOM.createPortal(
        /* TODO: make a comp that responds to auto changes in height... more elegant.. see https://gist.github.com/msaspence/8031944aafc25e051792b0161cdd0c22 */
        <>
          <Bg
            style={
              hide
                ? { opacity: 0, pointerEvents: "none", visibility: "hidden" }
                : {}
            }
            onClick={onClick}
            {...props}
          >
            <PanelProduct
              onlyCenterModals={onlyCenterModals}
              type={type}
              tabIndex={-1}
              ref={ref}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <AnimateHeight
                height={loading ? 300 : "auto"}
                duration={350}
                easing={"ease-out"}
              >
                {loading ? (
                  <div
                    className={"top"}
                    style={{ display: "flex", height: 300 }}
                  >
                    <IconLoaderCircle style={{ margin: "auto" }} />
                  </div>
                ) : (
                  <>
                    {onClose && <Close onClick={onClose} />}
                    {onBack && <Back onClick={onBack} />}
                    <div className={"top"}>
                      <>
                        {icon && (
                          <div
                            style={{ textAlign: "center", marginBottom: 20 }}
                          >
                            <IconContainer>
                              <IconCircle />
                              {icon}
                            </IconContainer>
                          </div>
                        )}
                        {children}
                      </>
                    </div>
                    {hasActions && (
                      <div
                        className={"bottom"}
                        style={{
                          justifyContent:
                            hasPrimary && hasSecondary
                              ? "space-between"
                              : "center",
                        }}
                      >
                        {hasSecondary && (
                          <ButtonGray
                            noShadow
                            style={{
                              minWidth: "calc(50% - 7px)",
                            }}
                            {...secondary}
                          />
                        )}
                        {hasPrimary && (
                          <ButtonPrimary
                            noShadow
                            style={{
                              minWidth: "calc(50% - 7px)",
                            }}
                            status={status}
                            {...primary}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </AnimateHeight>
            </PanelProduct>
          </Bg>
        </>,
        document.body
      )
    }
    case "prompt":
    default: {
      const hasPrimary = hasButton(primary)
      const hasSecondary = hasButton(secondary)
      const hasActions = hasPrimary || hasSecondary
      return ReactDOM.createPortal(
        /* TODO: make a comp that responds to auto changes in height... more elegant.. see https://gist.github.com/msaspence/8031944aafc25e051792b0161cdd0c22 */
        <>
          <Bg
            style={
              hide
                ? { opacity: 0, pointerEvents: "none", visibility: "hidden" }
                : {}
            }
            onClick={onClick}
            {...props}
          >
            <PanelPrompt
              onlyCenterModals={onlyCenterModals}
              type={type}
              tabIndex={-1}
              ref={ref}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <AnimateHeight
                height={loading ? 300 : "auto"}
                duration={350}
                easing={"ease-out"}
              >
                {loading ? (
                  <div
                    className={"top"}
                    style={{ display: "flex", height: 300 }}
                  >
                    <IconLoaderCircle style={{ margin: "auto" }} />
                  </div>
                ) : (
                  <>
                    {onClose && <Close onClick={onClose} />}
                    {onBack && <Back onClick={onBack} />}
                    <div className={"top"}>
                      <>
                        {icon && (
                          <div
                            style={{ textAlign: "center", marginBottom: 20 }}
                          >
                            <IconContainer>
                              <IconCircle />
                              {icon}
                            </IconContainer>
                          </div>
                        )}
                        {children}
                      </>
                    </div>
                    {hasActions && (
                      <div
                        className={"bottom"}
                        style={{
                          justifyContent:
                            hasPrimary && hasSecondary
                              ? "space-between"
                              : "center",
                        }}
                      >
                        {hasSecondary && (
                          <ButtonGray
                            noShadow
                            style={{
                              minWidth: "calc(50% - 7px)",
                            }}
                            {...secondary}
                          />
                        )}
                        {hasPrimary && (
                          <ButtonPrimary
                            noShadow
                            style={{
                              minWidth: "calc(50% - 7px)",
                            }}
                            status={status}
                            {...primary}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </AnimateHeight>
            </PanelPrompt>
          </Bg>
        </>,
        document.body
      )
    }
  }
}

export default Modals
