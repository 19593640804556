import React from "react"
import styled from "styled-components"
import IconCalendar from "../../icons/IconCalendar"
import { Content as C } from "../../core/ModalsShared"

// Same for Cancel + Successful bookings
export const Content = styled(C)`
  .booking {
    border-radius: 15px;
    color: #000;
    margin-bottom: 0.9rem;
    margin-top: 1rem;
    p {
      margin-bottom: 0px;
    }
    .tenant {
      text-transform: uppercase;
      font-size: 0.75rem;
    }
  }
  .first {
    font-weight: bold;
  }
`

export const BookingsModalIconCalendar = () => {
  return (
    <IconCalendar
      style={{
        margin: "auto",
        width: 33,
        height: "auto",
      }}
    />
  )
}
