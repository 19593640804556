import React from "react"
import IconLoaderDots from "../icons/IconLoaderDots"

const ButtonLoaderAlone = () => {
  return (
    <IconLoaderDots
      style={{
        fill: "#fff",
        height: "8px",
        width: "auto",
      }}
    />
  )
}

export default ButtonLoaderAlone
