import React from "react"
import IconLocation from "../../icons/IconLocation"
import IconTime from "../../icons/IconTime"
import { parseBookingText } from "../utils"
import IconGroup from "../../icons/IconGroup"
import styled from "styled-components"

const P = styled.p`
  display: flex;
  align-items: start;
  line-height: 1.3;
  overflow-wrap: anywhere;
  word-wrap: break-all;
  hyphens: auto;
`

// Keeping booking text here for booking + modal summary views so if we update one we don't forget to also update the other
const BookingsText = ({ booking, summary = false }) => {
  const { payment_type, price, type, where, specificTime, fromNow, 
    maxSeats, currentSeats } = booking || {}
  const { name, time } = parseBookingText(booking || {})
  const isFree = payment_type === "free"
  const iconContainerStyles = {
    width: "1.5em",
    height: "1.5em",
    position: "relative",
  }
  const iconStyles = {
    width: "1.5em",
    height: "1.5em",
    marginLeft: "-3px",
    marginRight: ".2em",
    display: "inline-block",
  }
  if (summary)
    return (
      <>
        <h4>{name}</h4>
        {time && (
          <P style={{ marginTop: "-.15em" }}>
            <span style={iconContainerStyles}>
              <IconTime alt={"time"} style={iconStyles} />
            </span>
            <span style={{ marginTop: ".15em" }}>{time}</span>
          </P>
        )}
        {type && type === "Group" && (
          <P style={{ marginTop: "-.15em" }}>
            <span style={iconContainerStyles}>
              <IconGroup group alt={type} style={iconStyles} />
            </span>
            <span style={{ marginTop: ".15em" }}>Group Session</span>
          </P>
        )}
        {where && (
          <P style={{ marginTop: "-.15em" }}>
            <span style={iconContainerStyles}>
              <IconLocation alt={"location"} style={iconStyles} />
            </span>
            <span style={{ marginTop: ".15em" }}>{where}</span>
          </P>
        )}
        {!isFree && price && (
          <P style={{ marginTop: "-.15em" }}>
            <span style={iconContainerStyles}>
              {/* Spacer */}
              <span style={iconStyles} />
            </span>
            <span style={{ marginTop: ".15em" }}>
              {payment_type === "optional" ? "Donation " : "Cost "}
              {`$${payment_type === "optional" ? Math.ceil(price) : price}`}
            </span>
          </P>
        )}
      </>
    )

  return (
    <>
      <h3 className={"name"}>{name}</h3>
      {time && (
        <P>
          <span style={iconContainerStyles}>
            <IconTime alt={"time"} style={iconStyles} />
          </span>
          <span style={{ marginTop: ".15em" }}>{time}</span>
        </P>
      )}
      {specificTime && (
        <P>
          <span style={iconContainerStyles}>
            <IconTime alt={"time"} style={iconStyles} />
          </span>
          <span style={{ marginTop: ".15em" }}>{specificTime}</span>
        </P>
      )}
      {fromNow && (
        <P>
          <span style={{ marginTop: ".15em" }}>{fromNow}</span>
        </P>
      )}
      {type && type === "Group" && (
        <P>
          <span style={iconContainerStyles}>
            <IconGroup group alt={type} style={iconStyles} />
          </span>
          <span style={{ marginTop: ".15em" }}>Group Session</span>
        </P>
      )}
      {maxSeats > 0 && (
        <P>
          <span style={iconContainerStyles}>
            <IconGroup group alt={type} style={iconStyles} />
          </span>
          <span style={{ marginTop: ".15em" }}>{maxSeats - currentSeats} slots available</span>
        </P>
      )}
      {where && (
        <P>
          <span style={iconContainerStyles}>
            <IconLocation alt={"location"} style={iconStyles} />
          </span>
          <span style={{ marginTop: ".15em" }}>{where}</span>
        </P>
      )}
      {!isFree && price && (
        <>
          <div className={"spacer"} style={{ flex: 1, minHeight: 15 }} />
          <p>
            {payment_type === "optional" ? "Optional Donation" : "Cost"}
            <span className={"price"}>{`$${payment_type === "optional" ? Math.ceil(price) : price
              }`}</span>
          </p>
        </>
      )}
    </>
  )
}

export default BookingsText
