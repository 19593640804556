import React from "react"
import CoinbaseCommerceButton from "react-coinbase-commerce"
import "react-coinbase-commerce/dist/coinbase-commerce-button.css"
import styled from "styled-components"
import { segmentAction } from "../../hooks/useSegmentTrack"

const CryptoButton = styled(CoinbaseCommerceButton)`
  box-shadow: none;
  min-width: 175px;
  font-size: 1.05rem;
  padding: 0.9em 1.6em;
  line-height: 1;
  font-weight: bold;
  background: var(--accent);
  color: white;
  border-radius: 2em;
  @media screen and (max-width: 800px) {
    font-size: 1.05rem;
  }
`

const SupportWithCrypto = ({ buttonText = "Donate With Crypto" }) => {
  return (
    <CryptoButton
      checkoutId={"5492fc04-8755-412c-9968-8bd5b272f527"}
      style={{
        gridArea: "support",
      }}
      onChargeSuccess={() => {
        segmentAction.track("Crypto Button Success")
      }}
      onChargeFailure={() => {
        segmentAction.track("Crypto Button Failure")
      }}
      onLoad={() => {
        // console.log("load coinbase checkout api")
        segmentAction.track("Crypto Button Load")
      }}
    >
      {buttonText}
    </CryptoButton>
  )
}

export default SupportWithCrypto
