import React, { useState } from "react"
import useCartPrePayment from "../../hooks/useCartPrePayment"
import { Content } from "../../core/ModalsShared"
import ProductLineItem from "./ProductLineItem"
import styled from "styled-components"
import usePrevious from "../../hooks/usePrevious"
import { formatter } from "../utils"
import ProductLineItemDeleted from "./ProductLineItemDeleted"
import { ButtonPrimary } from "../../core/Buttons"
import { EmailInput } from "../../sparklePage/support/Support"
import isEmail from "validator/lib/isEmail"
import IconBasket from "../../icons/IconBasket"
import ButtonLoaderWithText from "../../core/ButtonLoaderWithText"
import { segmentAction } from "../../hooks/useSegmentTrack"

const HeaderTitle = styled.h4`
  font-weight: bold;
  font-size: 0.8rem;
  margin: 0 0 8px 8px;
  text-align: left;
`
const FooterTitle = styled.h4`
  font-size: 0.9rem;
  margin: 0 0 0px 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`
const Container = styled.div`
  text-align: left;
  margin: 24px 0px 0px;
  font-variant-numeric: tabular-nums;
`
export const LineItemsContainer = styled.div`
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: 5px 0 15px;
  margin: 10px 0px;
`

// Goes inside a modal so we can use this content inside another modal ie our product one
const Cart = ({ onClose }) => {
  const [email, setEmail] = useState("")
  const isInvalidEmail = !isEmail(email || "")
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const [
    isProcessing,
    onCheckout,
    cart,
    addToCart,
    updateQuantities,
    tenantInfo,
  ] = useCartPrePayment()
  const prevCart = usePrevious(cart) || []
  const itemCount = cart.reduce((a, { quantity }) => {
    return a + quantity
  }, 0)
  const amount_total = formatter.format(
    cart.reduce((a, { quantity, product: { price } }) => {
      return a + quantity * price * 100
    }, 0) / 100
  )
  // Also include JUST deleted items
  const loopCart =
    cart.length > (prevCart || []).length
      ? cart
      : prevCart.map((prevCartItem) => {
          const found = cart.find(
            (cartItem) => cartItem.product.id === prevCartItem.product.id
          )
          if (found) return prevCartItem
          else return { ...prevCartItem, deleted: true }
        })
  const emptyCart = cart.length === 0
  return (
    <Content>
      <>
        <Container style={{ marginTop: 0 }}>
          <HeaderTitle>Cart</HeaderTitle>
          <LineItemsContainer>
            {emptyCart && (
              <>
                <h3
                  style={{
                    marginTop: 20,
                    fontWeight: "bold",
                    fontSize: "22px",
                    textAlign: "center",
                  }}
                >
                  Empty Cart
                </h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      margin: "10px 0",
                      position: "relative",
                      height: 160,
                      width: 160,
                      borderRadius: "100%",
                      background: "var(--accent-opacity-20)",
                    }}
                  >
                    <IconBasket
                      className={"pos-center"}
                      style={{ width: 175, height: "auto", fill: "black" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        width: 40,
                        height: 40,
                        right: 0,
                        bottom: 0,
                        fontSize: 32,
                        fontWeight: "bold",
                        background: "#fff",
                        borderRadius: "100%",
                        boxShadow: "0 0 0 4px #000",
                        lineHeight: "40px",
                        textAlign: "center",
                      }}
                    >
                      0
                    </div>
                  </div>
                </div>
              </>
            )}
            {loopCart.map(
              (
                {
                  product,
                  quantity,
                  deleted,
                  product: {
                    requiresShipping,
                    storePickupEnabled,
                    quantityAvailable,
                    imageUrl,
                  },
                } = {},
                i
              ) => {
                if (deleted)
                  return (
                    <ProductLineItemDeleted
                      key={product.id}
                      product={product}
                      onUndo={() => {
                        addToCart([{ product, quantity }])
                      }}
                    />
                  )
                return (
                  <ProductLineItem
                    addLink={true}
                    deleted={deleted}
                    imageUrl={imageUrl}
                    requiresShipping={requiresShipping}
                    storePickupEnabled={storePickupEnabled}
                    quantityAvailable={quantityAvailable}
                    tenantId={tenantInfo?.tenantId}
                    key={i}
                    productId={product.id}
                    onQtyChange={(v) => {
                      updateQuantities([{ product, quantity: v }])
                    }}
                    price={product.price * 100}
                    quantity={quantity}
                    itemType={"product"}
                    displayType={"cart"}
                    name={product.name}
                  />
                )
              }
            )}
          </LineItemsContainer>
          <FooterTitle>
            <span style={{ fontSize: ".8rem" }}>
              {`${itemCount} Item${itemCount !== 1 ? "s" : ""}`}
            </span>
            <span>
              <span style={{ marginRight: 3 }}>Total</span>{" "}
              <strong>{amount_total}</strong>
            </span>
          </FooterTitle>
        </Container>
        {/* Spacer */}
        <div style={{ height: 25 }} />
        {!emptyCart ? (
          <>
            <EmailInput
              disabled={isProcessing}
              as={"input"}
              style={{
                width: "100%",
              }}
              defaultValue={email}
              className={isInvalidEmail && clickedSubmit ? "error" : ""}
              required
              placeholder="Email"
              type={"email"}
              name={"email"}
              onChange={(e) => {
                const val = e.target.value
                setEmail(val)
              }}
            />
            <ButtonPrimary
              onClick={
                isInvalidEmail
                  ? () => setClickedSubmit(true)
                  : () => {
                      segmentAction.track(`Sparkles Cart Checkout Click`, {
                        cartItemCount: itemCount,
                        cartAmount: amount_total,
                      })
                      setClickedSubmit(true)
                      onCheckout(email)
                    }
              }
              style={{ width: "100%", marginTop: 10, height: 45 }}
            >
              {isProcessing ? "To Checkout" : "Checkout Now"}
              <ButtonLoaderWithText isProcessing={isProcessing} />
            </ButtonPrimary>
          </>
        ) : (
          <ButtonPrimary
            onClick={onClose}
            style={{ width: "100%", height: 45 }}
          >
            Continue Shopping
          </ButtonPrimary>
        )}
      </>
    </Content>
  )
}

export default Cart
