import React from "react"
import styled from "styled-components"
import TextOverflowReadMore from "../../core/TextOverflowReadMore"
import { Label } from "../../sparklePage/bookings/BookingsModalContactFormElems"

const Content = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0.4em;
    line-height: 1.5;
    a {
      color: var(--accent);
    }
  }
`

const ProductCustomerNotes = ({ onChange, notes }) => {
  return (
    <>
      {/* "desktopOnly" + "mobileOnly" defined in ProductPage container" */}
      {/* Test with a LOT of text :)  */}
      {/* DESKTOP ONLY - scrolling + fades */}
      <Content
        className={"desktopOnly"}
        style={{
          flex: "1 1 0",
          minHeight: "100px", // especially useful as width of screen lessens
        }}
      >
        <Label style={{ flex: 1, minHeight: 100 }}>
          <span>Add a note to the seller</span>
          <textarea
            style={{
              resize: "none",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
            }}
            lines={2}
            name="additionalInformation"
            placeholder="optional"
            defaultValue={notes}
            onChange={(e) => onChange(e.target.value)}
          />
        </Label>
      </Content>
      {/* MOBILE ONLY - click to expand */}
      <Content className={"mobileOnly"}>
        <Label style={{ flex: 1, minHeight: 100 }}>
          <span>Add a note to the seller</span>
          <textarea
            style={{
              resize: "none",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
            }}
            lines={2}
            name="additionalInformation"
            placeholder="optional"
            defaultValue={notes}
            onChange={(e) => onChange(e.target.value)}
          />
        </Label>
      </Content>
    </>
  )
}

export default ProductCustomerNotes
