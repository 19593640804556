import React from "react"

// Help with patterns - https://css-tricks.com/snippets/svg/svg-patterns/
const IconShoppinCartRepeat = ({ style = {}, id, ...props }) => {
  return (
    <svg style={{ width: "100%", height: "100%", ...style }} {...props}>
      <pattern
        id={id} // make unique... load only 1x in store comp
        patternUnits="userSpaceOnUse"
        viewBox="0 0 55 55"
        x="5"
        y="15"
        width="45"
        height="40"
      >
        <g transform={"scale(.5)"}>
          <path
            strokeWidth={0}
            d="M49.45.36l-22.33,12a3,3,0,0,0-1.23,4.06l12,22.33a3,3,0,0,0,4.06,1.23L64.24,28a3,3,0,0,0,1.23-4.06l-12-22.34A3,3,0,0,0,49.45.36Z"
          />
          <g
            fill={"none"}
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            strokeWidth={8}
          >
            <polyline points="4.06 16.9 14.51 17.81 22.23 50.64 70.83 50.64 78.64 22.83" />
            <circle cx="24.77" cy="66.53" r="3.66" />
            <circle cx="67.65" cy="66.53" r="3.66" />
          </g>
        </g>
      </pattern>
    </svg>
  )
}

export default IconShoppinCartRepeat
