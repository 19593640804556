import React, { useEffect, useState } from "react"
import axios from "axios"
import { capitalizeFirstLetter, parseTimeForBookingsSummary } from "../utils"
import moment from "moment"
import { ButtonPrimary } from "../../core/Buttons"
import BookingsCard from "./BookingsCard"
import PageSectionGridContainer from "../PageSectionGridContainer"
import { segmentAction } from "../../hooks/useSegmentTrack"

const BookingsModalGroupClassSelections = ({
  tenantId,
  tenantInfo,
  style,
  setView,
  selectedBooking,
  setBookingInfo,
  setTimeZone,
  timezone,
}) => {
  const { id, isGroupClass } = selectedBooking || {}
  const [classInstances, setClassInstances] = useState(new Array())
  const [classesLoaded, setClassesLoaded] = useState(false)

  useEffect(() => {
    if (id && isGroupClass) {
      // call for list of classes
      const baseURL = process.env.GATSBY_SPARKLE_API_PATH
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE
      const api = axios.create({ baseURL })
      api
        .post(`ListBookings?code=${apiCode}`, {
          tenantId: tenantId,
          projectId: id,
        })
        .then(response => {
          if (response.data) {
            setClassInstances(
              response.data.filter(
                o =>
                  o.state !== "cancelled" &&
                  o.attributes &&
                  o.attributes.event &&
                  moment(o.attributes.event.start) > moment()
              )
            )
          }

          setClassesLoaded(true)
        })
    }
  }, [id, isGroupClass])

  // not sure about this logic.. iiuc, activeClassInstances is intended to return array of objects matching condition but are also trying to return a boolean if disable? - OKRR
  const activeClassInstances = classInstances.filter(o => {
    const { group_booking } = o.attributes || {}
    const { max_seats, current_seats } = group_booking || {}
    // const disable = max_seats - current_seats <= 0
    // if (disable) return false
    // return true

    // return if state is not cancelled and if there are seats available
    // NOTE: might not be necessary to check for state cancelled here if we are already filtering out cancelled classes in the useEffect above
    return (
      o.state !== "cancelled" &&
      max_seats > current_seats &&
      moment(o.attributes.event.start) > moment()
    )
  })

  // perhaps can do something like this instead
  // helper function to check if class if full
  const hasAvailableSeats = classInstanceId => {
    const selectedGroupClass = activeClassInstances.find(
      o => o.id === classInstanceId
    )
    // console.log({ selectedGroupClass })
    const { group_booking } = selectedGroupClass.attributes || {}
    const { max_seats, current_seats, available_seats } = group_booking || {}

    // does available_seats currently work in prod?
    // return available_seats > 0
    return max_seats - current_seats > 0
  }

  return (
    <PageSectionGridContainer>
      {activeClassInstances &&
        activeClassInstances.length > 0 &&
        activeClassInstances.map(o => {
          const { id, attributes } = o || {}
          const { event } = attributes || {}
          const { date, time, fromNow } = parseTimeForBookingsSummary(
            event || {}
          )
          const { start, end, what: name, where, description } = event
          const { group_booking } = attributes || {}
          const { max_seats, current_seats } = group_booking || {}

          // const disable = max_seats - current_seats <= 0

          return !hasAvailableSeats(id) ? (
            <div
              style={{
                paddingTop: "15px;",
                color: "var(--error)",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Sorry, we're booked!
            </div>
          ) : (
            <BookingsCard
              key={o.id}
              booking={{
                name: date,
                specificTime: time,
                fromNow,
                maxSeats: max_seats,
                currentSeats: current_seats,
              }}
              onClick={e => {
                e.preventDefault()
                segmentAction.track(
                  `Sparkles Booking Group Class Timeslot Click`,
                  {
                    bookingId: o.id,
                  },
                  { tenantInfo: tenantId }
                )

                const nylasBookingInfo = {
                  resources: [{ id: null, timezone }],
                  start: moment(start),
                  end: moment(end),
                  timezone,
                  booking: {
                    graph: "group_owner",
                    id: o.id,
                    resource: null,
                  },
                }

                setBookingInfo(nylasBookingInfo)
                setTimeZone(nylasBookingInfo.timezone)
                setView("contact")
              }}
            />
          )
        })}
    </PageSectionGridContainer>
  )
}

export default BookingsModalGroupClassSelections
