import React from "react"

const IconEdit = (props) => {
  return (
    <svg viewBox="10 10 80 80" {...props}>
      <path d="M83.161,16.733c-5.793-5.793-10.924-6.658-13.467-6.658c-0.688,0-1.141,0.067-1.303,0.096  c-0.656,0.116-1.262,0.431-1.732,0.902L55.995,21.737c-0.001,0-0.001,0.001-0.001,0.001c-0.002,0.001-0.002,0.002-0.003,0.002  L18.755,58.977c-0.001,0.001-0.002,0.001-0.003,0.002s-0.002,0.002-0.002,0.003l-3.238,3.238c-0.452,0.452-0.761,1.028-0.888,1.655  l-4.478,22.156c-0.216,1.067,0.117,2.172,0.888,2.942c0.615,0.615,1.444,0.952,2.298,0.952c0.214,0,0.43-0.021,0.644-0.064  l22.155-4.479c0.627-0.126,1.202-0.436,1.654-0.887l51.147-51.147c0.49-0.49,0.811-1.123,0.915-1.808  C89.958,30.818,90.705,24.277,83.161,16.733z M38.729,74.359L25.646,61.278l32.645-32.645l13.082,13.082L38.729,74.359z   M17.487,82.519l3.313-16.396l0.25-0.25l13.082,13.081l-0.25,0.25L17.487,82.519z M75.969,37.118L62.888,24.037l7.424-7.424  c1.442,0.163,4.52,0.982,8.254,4.716c3.793,3.794,4.639,6.933,4.816,8.376L75.969,37.118z"></path>
    </svg>
  )
}

export default IconEdit
