import React from "react"
import IconCheck from "../../icons/IconCheck"

const ProductShippingText = () => {
  return (
    <span
      style={{
        color: "var(--success)",
        paddingLeft: ".25em",
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconCheck style={{ marginBottom: 2 }} />
      <span style={{ marginLeft: 8 }}>{`Shipping Included`}</span>
    </span>
  )
}

export default ProductShippingText
