import React, { useRef } from "react"
import styled from "styled-components"
import { useOverflow } from "use-overflow"
import { useScrolling } from "react-use"

const GradTop = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0px;
  opacity: 0; /* by default */
  height: 22px;
  width: 100%;
  transition: 0.25s 0.1s;
  transform-origin: center;
  transform: translateY(-100%);
  background: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0) 100%);
`
export const GradBottom = styled(GradTop)`
  top: unset;
  bottom: 0px;
  transform: translateY(100%) scaleY(-1);
`

const TextOverflowGradient = ({
  height = 22, // optional send in a diff gradient height
  children,
}) => {
  const textRef = useRef(null)
  const {
    refYOverflowing: overflowY,
    refYScrollBegin: atTopOfScroll,
    refYScrollEnd: atBottomOfScroll,
  } = useOverflow(textRef)
  const scrolling = useScrolling(textRef)
  if (!children) return null
  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "99%", // for weird lining up issues of gradients on top + bottom
          overflow: "auto",
        }}
        ref={textRef}
      >
        {children}
      </div>
      {/* Show gradient when at rest on top and or bottom depending on overflow */}
      <GradTop
        style={{
          height,
          transition: scrolling ? ".1s" : "", // While scrolling we don't want any long animations
          opacity: atTopOfScroll || scrolling || !overflowY ? 0 : 1,
          transform:
            atTopOfScroll || scrolling || !overflowY
              ? "translateY(-100%)"
              : "translateY(0)",
        }}
      />
      <GradBottom
        style={{
          height,
          transition: scrolling ? ".1s" : "",
          opacity: atBottomOfScroll || scrolling || !overflowY ? 0 : 1,
          transform:
            atBottomOfScroll || scrolling || !overflowY
              ? "translateY(100%) scaleY(-1)"
              : "translateY(0) scaleY(-1)",
        }}
      />
    </div>
  )
}

export default TextOverflowGradient
