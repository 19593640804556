import React from "react"
import styled from "styled-components"

const Text = styled.div`
  font-size: 17px;
  line-height: 0;
  margin: 0;
  height: 0.6em;
  display: flex;
  align-items: flex-start;
  margin-top: 0.8em;
`
const DollarSign = styled.span`
  font-size: 0.65em;
  /* padding-top: 0.15em; */
`
const Dollars = styled.span`
  padding-top: 0.115em;
`
const Cents = styled.span`
  margin-left: 0.0625em;
  font-size: 0.65em;
  /* padding-top: 0.15em; */
`
const ProductPrice = ({ price, ...props }) => {
  const [dollars, cents] = price.toFixed(2).split(".")
  return (
    <Text {...props}>
      <DollarSign>$</DollarSign>
      <span style={{ width: 1 }} />
      <Dollars>{dollars}</Dollars>
      {cents !== "00" && <Cents>{cents}</Cents>}
    </Text>
  )
}

export default ProductPrice
