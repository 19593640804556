import React from "react"

/* Carousel height that grows to the largest item to standardize slide heights */
// Uses power of flexbox to grow to largest item in row
// TODO: maybe add animation options pagination options, but should only be optional
function CarouselEqualHeight({
  items, // Arr of {elem: '', name: ''}
  active, // Matches name
}) {
  const len = items.length
  if (len <= 0) return null
  return (
    <div style={{ width: "100%", overflowY: "auto", overflowX: "hidden" }}>
      <div style={{ width: `${len * 100}%`, display: "flex" }}>
        {items.map(({ elem, name }, i) => {
          const show = active === name
          return (
            <div
              style={{
                flex: 1,
                order: show ? 1 : 2,
                pointerEvents: show ? "" : "none",
              }}
              key={i}
              className={active === name ? "show" : "hide"}
            >
              {elem}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CarouselEqualHeight
