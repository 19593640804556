import React from "react"
import IconStore from "../../icons/IconStore"

const ProductPickupText = () => {
  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <IconStore
        style={{ marginBottom: 2, height: "1.1em", position: "absolute" }}
      />
      <span style={{ marginLeft: "1.5em" }}>{`In-store pickup only`}</span>
    </span>
  )
}

export default ProductPickupText
