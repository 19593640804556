import React from "react"

const IconCalendar = (props) => {
  return (
    <svg viewBox="0 0 98 96" {...props}>
      <rect
        x="4"
        y="11"
        width="90"
        height="81"
        rx="12"
        stroke={"currentColor"}
        fill={"none"}
        strokeWidth="7"
      />
      <g fill={"currentColor"}>
        <rect x="15" y="49" width="17" height="11.7857" rx="4" />
        <rect x="41" y="49" width="17" height="11.7857" rx="4" />
        <rect x="67" y="49" width="17" height="11.7857" rx="4" />
        <rect x="15" y="68.2143" width="17" height="11.7857" rx="4" />
        <rect x="41" y="68.2143" width="17" height="11.7857" rx="4" />
        <rect x="67" y="68.2143" width="17" height="11.7857" rx="4" />
        <rect x="21" width="13" height="23" rx="3" />
        <rect x="65" width="13" height="23" rx="3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94 41H4V23C4 16.3726 9.37256 11 16 11H82C88.6274 11 94 16.3726 94 23V41Z"
        />
      </g>
    </svg>
  )
}

export default IconCalendar
