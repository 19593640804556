import React, { useState } from "react"
import isEmail from "validator/lib/isEmail"
import axios from "axios"
import { Submit, EmailInput as EI } from "../../sparklePage/support/Support"
import ButtonLoaderWithText from "../../core/ButtonLoaderWithText"
import styled from "styled-components"
import { segmentAction } from "../../hooks/useSegmentTrack"

const getSubmitText = ({ isProcessing, onSuccess, showBackToProducts }) => {
  if (showBackToProducts) return "Continue Shopping"
  if (onSuccess) return "Success!"
  if (isProcessing) return "Processing"
  return "Notify Me"
}

const EmailInput = styled(EI)`
  transition: 0.2s;
  ${({ animateHide }) => {
    if (animateHide) {
      return `
        padding: 0;
        margin: 0;
        height: 0;
        font-size: 0;
        line-height: 0;
        box-shadow: none;
        filter: none;
        border: none !important;
        &:focus-visible {
          border: none !important;
        }
    `
    }
  }}
`

// Request to be notified when in stock
const ProductOutOfStock = ({ tenantId, productId, onClose, item }) => {
  const [email, setEmail] = useState("")
  const isInvalidEmail = !isEmail(email || "")
  const [showBackToProducts, setShowBackToProducts] = useState(false)
  const [clickedOnSubmit, setClickedOnSubmit] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false) // TODO: into a reducer?.. all the states?
  const [onSuccess, setOnSuccess] = useState(false) // Only if out of stock
  const onToBeNotifiedInStock = () => {
    setClickedOnSubmit(true)
    if (showBackToProducts) onClose()
    if (isInvalidEmail || onSuccess) return // exit

    segmentAction.track(
      "Sparkles Product Notify Instock",
      {},
      { product: item }
    )

    const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
    setIsProcessing(true)
    const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
    api
      .post(`HandleInStockNotificationRequest?code=${apiCode}`, {
        tenantId,
        productId,
        customerName: "",
        customerEmail: email,
      })
      .then((response) => {
        // TODO: MAKE SURE THIS ACTUALLY WORKS
      })
      .catch((error) => {
        // TODO: SHOW THIS ERROR?
        console.log(error)
      })
      .finally((o) => {
        setIsProcessing(false)
        setOnSuccess(true)
        setTimeout(() => setShowBackToProducts(true), 2000)
      })
  }
  return (
    <>
      <p style={{ marginBottom: 15 }}>
        {!onSuccess ? (
          <>
            Notify me when item is{" "}
            <span
              style={{
                textUnderlineOffset: ".2em",
                textDecoration: "underline",
              }}
            >
              back in stock
            </span>
          </>
        ) : (
          <>
            Success! <strong>{email}</strong> will be notified when product is
            back in stock.
          </>
        )}
      </p>
      {isInvalidEmail && clickedOnSubmit && (
        <p style={{ color: "var(--error)" }}>Provide valid email</p>
      )}
      <EmailInput
        disabled={isProcessing}
        onChange={(e) => {
          const value = e.target.value
          setEmail(value)
        }}
        as={"input"}
        animateHide={onSuccess}
        style={!onSuccess ? { marginBottom: "10px" } : {}}
        required
        placeholder="Email"
        type={"email"}
        name={"email"}
      />
      <Submit
        className={isProcessing ? "loading" : ""}
        onClick={onToBeNotifiedInStock}
        style={{ margin: 0 }}
      >
        {getSubmitText({ isProcessing, onSuccess, showBackToProducts })}
        <ButtonLoaderWithText isProcessing={isProcessing} />
      </Submit>
    </>
  )
}

export default ProductOutOfStock
