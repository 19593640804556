import { useEffect, useState } from "react"
import moment from "moment"
import useWinDim from "./useWinDim"
import useMaxedOutBookings from "./useMaxedOutBookings"
import useTimekitWidgetLoad from "./useTimekitWidgetLoad"
import usePrevious from "../hooks/usePrevious"
import { segmentAction } from "./useSegmentTrack"

const GATSBY_SPARKLE_TIMEKIT_WIDGET_ID =
  process.env.GATSBY_SPARKLE_TIMEKIT_WIDGET_ID

export const timekitHeightPadding = 30 // For some reason timekit needs more height than we tell it to render at
export const summaryHeight = 140 // Really a min-height but a good one

const useTimekitWidgetSet = ({ selectedBookingId, tenantInfo }) => {
  const [widget, widgetLoaded] = useTimekitWidgetLoad()
  const [view, setView] = useState("first") // default
  const dim = useWinDim({})
  const listView = dim.width > 1 && dim.width < 600
  let timekitHeight = 1 // TODO: use this height to display in modal right away?
  if (dim.height > 1) {
    const mobileTopPad = 160 // So doesn't hit top of mobile page if it doesn't need to... also takes into account summary expanding and some padding + heading space from modal content headers
    timekitHeight =
      dim.height < 500 + summaryHeight + timekitHeightPadding + mobileTopPad
        ? Math.max(
            dim.height - (summaryHeight + timekitHeightPadding + mobileTopPad),
            420
          ) // small screens
        : 500 // most screens
  }

  const { maxedOutDays, maxedOutDaysByAppointmentType, maxedOutDaysLoaded, setRefreshMaxedOutDays } =
    useMaxedOutBookings({ tenantInfo }) // Some days can only have so many bookings
  const [bookingInfo, setBookingInfo] = useState({})
  const [showBookingForm, setShowBookingForm] = useState(false)
  const [timeZone, setTimeZone] = useState("")
  const previous = usePrevious({
    selectedBookingId,
    maxedOutDaysLoaded,
    timekitHeight,
  })

  useEffect(() => {
    if (
      selectedBookingId &&
      maxedOutDaysLoaded &&
      !!widgetLoaded &&
      widget &&
      timekitHeight > 1 && // 1 is default height till window exists
      // Prevent unnecessary reloading of timekit by checking previous
      (previous.selectedBookingId !== selectedBookingId ||
        previous.maxedOutDaysLoaded !== maxedOutDaysLoaded ||
        timekitHeight !== previous.timekitHeight)
    ) {
      // Mutual Availability Calculation
      // Step 1.  Get matching appointment type
      const matchingAppointmentType = tenantInfo && tenantInfo.tenantAppointmentTypes && 
        tenantInfo.tenantAppointmentTypes.find(o => o.id === selectedBookingId)

      // Step 2. Does matching appointment type have more than 1 calendar id required for availability?
      const hasMutualAvailabilityRequired = matchingAppointmentType && matchingAppointmentType.calendarIds && matchingAppointmentType.calendarIds.length > 1

      // Step 3. If yes, set required mutually available resources
      const mutualAvailabilityResources = hasMutualAvailabilityRequired && matchingAppointmentType && matchingAppointmentType.calendarIds

      widget.init({
        app_key: GATSBY_SPARKLE_TIMEKIT_WIDGET_ID,
        project_id: selectedBookingId,
        availability: {
          mode: hasMutualAvailabilityRequired ? 'mutual' : 'roundrobin_prioritized',
        },
        resources: hasMutualAvailabilityRequired ? mutualAvailabilityResources : null,
        disable_confirm_page: true,
        fullcalendar: {
          // plugins: [listPlugin, dayGridPlugin, timeGridPlugin], Prob is that loading fullcalendar bring in a bunch of unnecessary styling which is required for the plugins
          defaultView: listView ? "list" : "month",
          titleRangeSeparator: " – ",
          header: {
            center: "title",
            right: "today,prev,next",
          },
          views: {
            list: {
              // listDayAltFormat: "dddd MMMM D", // right side listDaySideFormat for new versions of fullcalendar
              listDayFormat: "dddd MMMM D", // left side
              duration: { weeks: 1 },
              noEventsMessage: "No available times for this timeframe",
            },
          },
          height: timekitHeight,
        },
        callbacks: {
          clickTimeslot(timeslot) {
            // TODO: for booking through in tenantInfo + id?
            segmentAction.track(
              `Sparkles Booking Timeslot Click`,
              {
                bookingId: selectedBookingId,
              },
              { tenantInfo }
            )
            setBookingInfo(timeslot)
            setView("contact")
            setShowBookingForm(true)
          },
          renderCompleted() {
            const select = document.querySelector(
              ".bookingjs-footer-tz-picker-select"
            )
            if (select) {
              if (select.value) {
                const initValue = timeZone ? timeZone : select.value
                // Set State
                if (timeZone !== initValue) setTimeZone(initValue)
                // Set UI Dropdown
                if (select.value !== initValue) select.value = initValue
              }
              // TODO: Look into clean up of this on dismount?
              select.addEventListener("change", (e) => {
                segmentAction.track(
                  `Sparkles Booking Timezone Change`,
                  {
                    bookingId: selectedBookingId,
                    value: e.target.value,
                  },
                  { tenantInfo }
                )

                const selectValue = document.querySelector(
                  `.bookingjs-footer-tz-picker-select option[value="${e.target.value}"]`
                )

                if (selectValue && selectValue.dataset && selectValue.dataset.target) {
                  setTimeZone(selectValue.dataset.target)
                } else {
                  setTimeZone(e.target.value)
                }
              })
            }
          },
          fetchAvailabilitySuccessful(response) {
            response.data = response.data.filter((o) => {
              const availableDayTime = moment(o.start).format("MM/DD/yyyy")

              // Maxed out days in general
              const maxedOutDaysArray = maxedOutDays || []
              const maxedOutDay = maxedOutDaysArray.find(
                (p) => moment(p).format("MM/DD/yyyy") === availableDayTime
              )

              // Maxed out day per appointment type
              const maxedOutDaysByAppointmentTypeArray = maxedOutDaysByAppointmentType || []
              const maxedOutDayForAppointmentType = maxedOutDaysByAppointmentTypeArray.find(
                (p) => p.appointmentTypeId === selectedBookingId && moment(p.maxedOutDay).format("MM/DD/yyyy") === availableDayTime
              )

              return !maxedOutDay && !maxedOutDayForAppointmentType
            })
          },
        },
      })
    }
  }, [
    selectedBookingId,
    maxedOutDays,
    maxedOutDaysLoaded,
    widget,
    widgetLoaded,
    timekitHeight,
    listView,
    timeZone,
    previous,
    tenantInfo,
  ])
  // Return useful states + setStates
  return {
    view,
    timeZone,
    bookingInfo,
    showBookingForm,
    timekitHeight,
    setBookingInfo,
    setRefreshMaxedOutDays,
    setShowBookingForm,
    setView,
    setTimeZone
  }
}

export default useTimekitWidgetSet
