import { useEffect } from "react"
import useBookingRedirectToStripe from "./useBookingRedirectToStripe"

const useBookingRequiredPaymentToStripe = ({
  customerInfo,
  pendingPaymentId,
  tenantInfo,
  bookingInfo,
  setIsProcessing,
  selectedBooking,
  voluntaryContribution,
}) => {
  // A little in-elegant but this lets us use stripe redirect on click or useEffect change (see useBookingRedirectToStripe)
  // When payment isn't required we'll use this on a click instead.
  const redirectToStripe = useBookingRedirectToStripe({
    customerInfo,
    voluntaryContribution,
    pendingPaymentId,
    tenantInfo,
    bookingInfo,
    onProcessing: () => setIsProcessing(true),
    onDone: () => setIsProcessing(false),
    selectedBooking,
  })

  useEffect(() => {
    // Automatic pass to stripe for required payments
    if (pendingPaymentId) {
      redirectToStripe()
    }
  }, [pendingPaymentId])
}

export default useBookingRequiredPaymentToStripe
