import React from "react"
import he from "he";
import { renderToString } from 'react-dom/server'
import { Submit } from "./BookingsModalContactFormElems"
import TextOverflowGradient from "../../core/TextOverflowGradient"
import Linkify from "react-linkify"
import { getArrFromParas } from "../../utils/text"

const BookingsModalDescription = ({ style, setView, selectedBooking }) => {
  const { description = "", isGroupClass = false } = selectedBooking || {}
  if (!description) return null   

  return (
    <>
      <div
        style={{
          overflow: "auto",
          height: "100%",
          ...style,
        }}
      >
        <TextOverflowGradient>          
            {getArrFromParas(description).map((t, i) => {
              const linkifyDescription = (<Linkify>{t}</Linkify>)
              const linkifyDescriptionString = renderToString(linkifyDescription);
              const decodedHtmlString = t.includes('<img ') && (t.includes('</img>') || t.includes('/>')) ? t : he.decode(linkifyDescriptionString)

              return (
                <p
                  key={i}
                  style={{
                    lineHeight: 1.5,
                    fontSize: ".98rem",
                    marginBottom: ".5em",
                  }}
                  dangerouslySetInnerHTML={{__html:decodedHtmlString}}
                />
              )
            })}
        </TextOverflowGradient>
      </div>
      <Submit style={{ marginTop: 20 }} onClick={() => isGroupClass ? setView("group-class-list") : setView("calendar")}>
        Book
      </Submit>
    </>
  )
}

export default BookingsModalDescription
