import React from "react"
import queryString from "query-string"
import { useLocation } from "@reach/router"
import PurchaseSuccessBooking from "./PurchaseSuccessBooking"
import PurchaseSuccessProducts from "./PurchaseSuccessProducts"
import { navigate } from "gatsby-link"
import Modals from "../core/Modals"
import PageErrorForm from "../sparklePage/PageErrorForm"
import useTenantInfoContext from "../hooks/useTenantInfoContext"

const Error = ({ tenantUrl, segmentLog }) => {
  const toHomepage = () => navigate(`/${(tenantUrl || "").toLowerCase()}/`)
  return (
    <Modals onClick={toHomepage} onClose={toHomepage}>
      <PageErrorForm
        segmentLog={segmentLog}
        btnText={"Close"}
        onClick={toHomepage}
        style={{ paddingBottom: 15 }}
      />
    </Modals>
  )
}

const PurchaseSuccess = ({ tenantUrl }) => {
  const location = useLocation()
  const { bookingId, session_id, productId } = queryString.parse(
    location.search
  )
  const tenantInfo = useTenantInfoContext()

  // No need to do Load tracking here since we also load their own local page

  if (session_id && bookingId)
    return (
      <PurchaseSuccessBooking
        tenantUrl={tenantUrl}
        tenantInfo={tenantInfo}
        session_id={session_id}
        bookingId={bookingId}
        errorComp={
          <Error tenantUrl={tenantUrl} segmentLog={"in success booking"} />
        }
      />
    )
  if (session_id)
    return (
      <PurchaseSuccessProducts
        tenantUrl={tenantUrl}
        tenantInfo={tenantInfo}
        sessionId={session_id}
        productId={productId}
        errorComp={
          <Error tenantUrl={tenantUrl} segmentLog={"in success products"} />
        }
      />
    )

  return null
}

export default PurchaseSuccess
