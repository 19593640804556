import React from "react"

const IconDownload = ({ strokeWidth = 2, ...props }) => {
  return (
    <svg viewBox="0 0 114 128" {...props}>
      <g stroke={"currentColor"} fill="none">
        <path
          vectorEffect="non-scaling-stroke"
          transform={"translate(0, 10)"}
          d="M7 75V109C7 110.105 7.89543 111 9 111H105C106.105 111 107 110.105 107 109V75"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M57 6V78M57 78L34 55.1429M57 78L80 55.1429"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default IconDownload
