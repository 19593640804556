import React from "react"
import { ButtonPrimary, ButtonGray } from "../../core/Buttons"
import { InputText } from "../../core/Inputs"
import styled from "styled-components"
import IconDownload from "../../icons/IconDownload"
import IconClose from "../../icons/IconClose"
import IconFile from "../../icons/IconFile"
import AnimateHeight from "react-animate-height"
import isEmail from "validator/lib/isEmail"
import { containerStyling } from "../../core/utils"

const closedHeight = 58
const closedPad = 9
const btnLength = 40
const openPad = 18

const Accordion = styled.div`
  ${containerStyling}
  width: 100%;
  padding: 0;
  display: block;
  margin-bottom: ${closedPad}px;
  transition: 0.2s;
  border-radius: ${closedHeight / 2}px;
  min-height: ${closedHeight}px;
  position: relative;
  overflow: hidden;
`
const TopContainer = styled.div`
  cursor: pointer;
  padding: ${closedPad}px;
  display: flex;
  min-height: ${closedHeight}px;
  align-items: center;
  justify-content: center;
`
const ButtonCancel = styled(ButtonGray)`
  padding: 0;
  width: ${btnLength}px;
  height: ${btnLength}px;
  top: ${closedPad}px;
  right: ${closedPad}px;
  position: absolute;
  display: flex;
`
const ButtonDownload = styled(ButtonPrimary)`
  width: ${btnLength}px;
  height: ${btnLength}px;
  overflow: hidden;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  display: flex;
`
const BottomContainer = styled.div`
  min-height: 58;
  padding: ${openPad}px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`
const Label = styled.label`
  font-size: 16px;
  padding-left: 16px;
  margin-bottom: 8px;
  display: block;
  @media (max-width: 600px) {
    font-size: 0.95rem;
  }
`
const IconFileDef = styled(IconFile)`
  margin-right: 10px;
  width: 16px;
  height: auto;
  stroke-width: 2px;
`

const DownloadsFile = ({
  onCancel,
  onOpen,
  open,
  file,
  text,
  email,
  ext,
  setEmail,
  placeholder,
  onEmailSubmit,
}) => {
  const validEmail = isEmail(email)
  const canDownload = validEmail && open
  return (
    <Accordion>
      <TopContainer onClick={open ? onCancel : onOpen}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: btnLength,
            paddingLeft: btnLength,
          }}
        >
          <IconFileDef />
          <span style={{ flex: 1 }}>{`${text}${ext ? ` (${ext})` : ""}`}</span>
        </div>
        {/* Close Accordion / Cancel Btn */}
        {open && (
          <ButtonCancel
            alt={"Close accordion"}
            onClick={() => {
              if (onCancel) onCancel()
            }}
          >
            <IconClose
              length={2}
              style={{
                width: "35%",
                height: "35%",
                margin: "auto",
              }}
            />
          </ButtonCancel>
        )}
        {/* Open Accordion / Download File Btn*/}
        <ButtonDownload
          alt={open ? "Download file" : "Open accordion"}
          {...(canDownload
            ? {
                as: "a",
                download: true,
                href: file,
                rel: "noopener noreferrer",
                target: "_blank",
              }
            : {})}
          style={{
            right: closedPad,
            bottom: open ? openPad : closedPad,
            transition: `260ms ${open ? "ease-out" : "ease-in"}`,
          }}
          onClick={open ? onEmailSubmit : onOpen} // switches
        >
          <IconDownload
            style={{
              width: 18,
              height: "auto",
              margin: "auto",
              fill: "#fff",
              transform: "translateY(-4%)",
            }}
          />
        </ButtonDownload>
      </TopContainer>
      {/* Bottom Portion */}
      <AnimateHeight
        ease={open ? "ease-out" : "ease-in"}
        duration={200}
        height={open ? "auto" : 0}
      >
        <BottomContainer>
          <Label>Submit email to download</Label>
          {/* TODO: Add some warnings when it doesn't go through (states) */}
          <div style={{ display: "flex" }}>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={placeholder}
              type={"email"}
              autoComplete={"email"}
              required
              style={{
                flex: 1,
              }}
            />
            {/* A spacer */}
            <span style={{ width: btnLength }} />
          </div>
        </BottomContainer>
      </AnimateHeight>
    </Accordion>
  )
}

export default DownloadsFile
