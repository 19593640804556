import { useEffect, useRef, useState } from "react"
import useIsBrowser from "./useIsBrowser"

// Booking.js is now available as local variable TimekitBooking instead of global window.timekitBooking <--- not sure what this means

const useTimekitWidgetLoad = () => {
  const widget = useRef(null)
  const isBrowser = useIsBrowser()
  const [widgetLoaded, setWidgetLoaded] = useState(false)
  useEffect(() => {
    const tempRef = widget // hold onto for unmount
    if (isBrowser) {
      tempRef.current = new window.TimekitBooking()
      setWidgetLoaded(true)
    }
    return () => {
      tempRef.current = null
    }

    // If you want to include not from gatsby-ssr
    // use...
    // import("timekit-booking").then(({ default: TimekitBooking }) => {
    // tempRef.current = TimekitBooking()
    // setWidgetLoaded(true)
    // And import jquery too and forgo ssr scripts
  }, [isBrowser])
  return [widget ? widget.current : null, widgetLoaded]
}

export default useTimekitWidgetLoad
