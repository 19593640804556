import React from "react"
import styled from "styled-components"
import IconSupport from "../../icons/IconSupport"
import ProductQuantitySelect from "./ProductQuantitySelect"
import ProductImage from "./ProductImage"
import useProductMaxQty from "../../hooks/useProductMaxQty"
import { formatter } from "../utils"
import { navigate } from "gatsby"
import Linkify from "react-linkify"
import ProductShippingText from "./ProductShippingText"
import IconCalendar from "../../icons/IconCalendar"

const borderRadius = "10px"
const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 15px;
  border-radius: ${borderRadius};
  background: #f7f7f7;
  display: flex;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`
const TopLine = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`
const Name = styled.h5`
  font-weight: bold;
  text-align: left;
`
const Circle = styled.div`
  margin: auto;
  opacity: 0.65;
  display: flex;
  color: var(--accent);
  border-radius: 100%;
  border: 3px solid #ccc;
`

const ProductLineItem = ({
  itemType: type,
  onQtyChange,
  additionalConfirmation,
  customerNotes,
  quantity = 0,
  productId,
  name,
  price = 0,
  imageUrl,
  quantityAvailable,
  storePickupEnabled,
  requiresShipping,
  tenantId,
  descriptions = [],
}) => {
  const maxQty = useProductMaxQty({
    quantityAvailable,
    productId,
    tenantId,
  })
  const isContribution = type === "contribution"
  const isBooking = type === "booking"
  const isProduct = !isBooking && !isContribution // find out what comes back here
  const amount_total = formatter.format((quantity * price) / 100)
  const unit_cost = formatter.format(price / 100)
  return (
    <div style={{ marginTop: "12px" }}>
      <div style={{ display: "flex", fontSize: ".8rem", textAlign: "left" }}>
        <ImgContainer
          {...!isProduct && {
            onClick: () => navigate(`?productId=${productId}#myshop`),
            as: "button",
          }}
        >
          {isContribution && (
            <IconSupport
              style={{
                width: 65,
                height: "auto",
                margin: "auto",
              }}
            />
          )}
          {isProduct && (
            <ProductImage
              style={{
                borderRadius, // Pass borderRadius for empty border
              }}
              productId={productId}
              alt={name}
            />
          )}
          {isBooking && (
            <Circle style={{ width: 70, height: 70, display: "flex" }}>
              <IconCalendar
                style={{
                  width: 40,
                  height: "auto",
                  margin: "auto",
                }}
              />
            </Circle>
          )}
        </ImgContainer>
        <div style={{ flex: 1, display: "flex" }}>
          <div
            style={{
              margin: "auto 0",
              width: "100%",
            }}
          >
            <TopLine>
              <Name
                {...!isProduct && {
                  onClick: () => navigate(`?productId=${productId}#myshop`),
                  as: "button",
                }}
              >
                {name}
              </Name>
              {price !== 0 && (
                <span style={{ paddingLeft: ".5rem" }}>{amount_total}</span>
              )}
            </TopLine>
            <div
              style={{
                textTransform: "uppercase",
                fontSize: "11.5px",
              }}
            >
              {/* Descriptions mostly used for bookings */}
              {descriptions.map((text, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      marginBottom: 3,
                      textTransform: "none",
                      fontSize: "1.125em",
                    }}
                  >
                    {text}
                  </div>
                )
              })}
              {/* Shipping */}
              <div style={{ textTransform: "none" }}>
                {storePickupEnabled && (
                  <div style={{ marginBottom: 3 }}>In-store pickup only</div>
                )}
                {requiresShipping && (
                  <div style={{ marginBottom: 3 }}>
                    <ProductShippingText />
                  </div>
                )}
              </div>
              {price !== 0 && !isBooking && (
                <>
                  <div>Unit Cost: {unit_cost}</div>
                  {onQtyChange ? (
                    <div
                      style={{
                        marginTop: 5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ProductQuantitySelect
                        fontSize={14}
                        allowForZero
                        onChange={onQtyChange}
                        maxQty={maxQty}
                        label={"Qty"}
                        qty={quantity}
                        style={{
                          padding: "6px 12px",
                          fontSize: "12px",
                        }}
                      />
                      <button
                        onClick={() => onQtyChange(0)}
                        style={{
                          fontSize: "10.5px",
                          textTransform: "none",
                          marginLeft: 10,
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <div>Qty: {quantity}</div>
                  )}
                </>
              )}
              {/* Additional info about items */}
              {/* Sometimes a thank you msg... displays AFTER purchasing  */}
              {(additionalConfirmation || customerNotes) && (
                <div
                  style={{
                    textTransform: "none",
                    fontSize: "1.2em",
                    lineHeight: 1.4,
                    marginTop: 8,
                    marginBottom: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Order Notes:</span>
                  {customerNotes && (
                    <>
                      <br />
                      {customerNotes}
                    </>
                  )}
                  {additionalConfirmation && (
                    <>
                      <br />
                      <Linkify>{additionalConfirmation}</Linkify>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductLineItem
