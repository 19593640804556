import React from "react"
import ProductCard from "./store/ProductCard"
import IconShoppingCartRepeat from "../icons/IconShoppingCartRepeat"
import PageSectionGridContainer from "./PageSectionGridContainer"
import queryString from "query-string"
import ProductPage from "./store/ProductPage"
import { navigate } from "gatsby"
import { clearHashAndQueries, getEmptyProductPatternId } from "./utils"
import Modals from "../core/Modals"
import Cart from "./store/Cart"
import { useCartContext } from "../hooks/useCartPrePayment"
import { segmentAction } from "../hooks/useSegmentTrack"

const PageShop = ({
  location,
  tenantInfo = {},
  tenantInfo: { tenantProducts: products = [] } = {},
}) => {
  const onClose = () => {
    navigate(clearHashAndQueries() + "#myshop")
  }
  const { productId, cart } = queryString.parse(location.search)
  const [, , loading] = useCartContext()
  const selectedProduct = products.find(({ id }) => id === productId)
  const hasSelectedProduct = !!selectedProduct

  return (
    <PageSectionGridContainer>
      {/* Define 1x here */}
      <IconShoppingCartRepeat
        id={getEmptyProductPatternId(tenantInfo)}
        style={{
          position: "absolute", // to get out of the way of flex items
          stroke: "rgba(0,0,0,.2)",
          fillOpacity: 0.4,
          fill: "var(--accent)", // TODO: make this more about luminosity
          width: 50,
          height: "auto",
        }}
      />
      {products
        .sort((a, b) => a.order - b.order)
        .map((product) => {
          const onClick = () => {
            segmentAction.track(`Sparkles Product Click`, {}, { product })
            navigate(`?productId=${product.id}#myshop`)
          }
          return (
            <ProductCard
              onClick={onClick}
              showTenant={false}
              tenantInfo={tenantInfo}
              key={product.id}
              item={product}
            />
          )
        })}
      {(hasSelectedProduct || cart) && (
        <Modals
          onClick={onClose}
          type={cart ? "" : "product"}
          loading={cart && loading} // cart not yet loaded... will show spinner can see on reload if cart is in query string
          onClose={onClose}
        >
          {cart === "true" ? (
            <Cart onClose={onClose} />
          ) : (
            <ProductPage
              onClose={onClose}
              item={selectedProduct}
              onAddToCart={() => navigate(`?&cart=true#myshop`)}
            />
          )}
        </Modals>
      )}
    </PageSectionGridContainer>
  )
}

export default PageShop
