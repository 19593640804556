import { useEffect, useState } from "react"
import axios from "axios"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useBookingInfo = ({ bookingId, tenantInfo, onSuccess, onError }) => {
  const [bookingInfo, setBookingInfo] = useState(null)
  useEffect(() => {
    if (bookingId && tenantInfo && tenantInfo.tenantId) {
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`ListBookings?code=${apiCode}`, {
          tenantId: tenantInfo.tenantId,
          bookingId,
          includeEventInfo: true,
        })
        .then((response) => {
          if (response && response.data && response.data[0]) {
            setBookingInfo(response.data[0])
            if (onSuccess) onSuccess()
          }
        })
        .catch((error) => {
          console.log(error)
          if (onError) onError()
        })
        .finally(() => {})
    }
  }, [bookingId, tenantInfo, onSuccess, onError])
  return bookingInfo
}

export default useBookingInfo
