import styled from "styled-components"

const InputReset = styled.input`
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
`

export const InputText = styled(InputReset).attrs((props) => ({
  type: "text",
}))`
  font-size: 16px;
  background: #efefef;
  border-radius: 300px; /* absurdly big */
  color: #222;
  height: 40px;
  line-height: 40px;
  padding: 0px 1em;
`
