import { useEffect, useState } from "react"
import axios from "axios"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useBookingPaidComplete = ({
  session_id: sessionId,
  bookingInfo,
  tenantInfo: { tenantId, tenantUrl } = {},
}) => {
  const [error, setError] = useState(false)
  const { appointmentTypeId } = bookingInfo || {}
  const [details, setDetails] = useState(null)
  const [requested, setRequested] = useState(false)
  const { session: { payment_status, metadata: { bookingId } = {} } = {} } =
    details || {}

  // Get session data
  useEffect(() => {
    if (tenantId && sessionId) {
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`GetStripeCheckoutSession?code=${apiCode}`, {
          tenantId,
          sessionId,
          appointmentTypeId,
        })
        .then((response) => {
          if (response && response.data) {
            setDetails(response.data)
          } else {
            setError(true)
          }
        })
        .catch((error) => {
          setError(true)
        })
    }
  }, [tenantUrl, tenantId, sessionId, appointmentTypeId, setError])

  // Complete Booking (success paid and has pending booking id)
  // - Now also happening from stripe... this is just a backup?
  // - TODO: Possibly remove... or only log if an error?
  useEffect(() => {
    if (!requested && payment_status === "paid" && bookingId) {
      setRequested(true)
      const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      let apiCode = process.env.GATSBY_SPARKLE_API_CODE
      api
        .post(`CreateBooking?code=${apiCode}`, {
          pendingPaymentBookingId: bookingId,
          tenantId
        })
        .then((response) => {
          // This is a success!!! Yay!
          // This is done in the background so doesn't need to surface to UI unless there is an error
        })
        .catch((error) => {
          // Timekit could go down
          setError(true)
        })
        .finally(() => {})
    }
  }, [bookingId, requested, payment_status, setError])
  return { error, price: details?.lineItems?.data?.[0]?.price?.unit_amount }
}

export default useBookingPaidComplete
