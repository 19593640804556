import React, { useState, useEffect } from "react"
import { segmentAction } from "../../hooks/useSegmentTrack"
import styled from "styled-components"
import PageErrorForm from "../PageErrorForm"
import BookingsModalSummary from "./BookingsModalSummary"
import BookingsModalContact from "./BookingsModalContact"
import Modals from "../../core/Modals"
import BookingsModalCalendar from "./BookingsModalCalendar"
import BookingsModalNylas from "./BookingsModalNylas"
import Content from "./BookingsModalContent"
import BookingsSuccess, {
  getBookingSuccessProps,
} from "../../sparkleSuccess/BookingsSuccess"
import useBookingRequiredPaymentToStripe from "../../hooks/useBookingRequiredPaymentToStripe"
import queryString from "query-string"
import { onContactSubmit } from "../utils"
import useWidgetModeContext from "../../hooks/useWidgetModeContext"
import BookingsModalDescription from "./BookingsModalDescription"
import CarouselEqualHeight from "../../core/CarouselEqualHeight"
import {
  timekitHeightPadding,
  summaryHeight,
} from "../../hooks/useTimekitWidgetSet"
import BookingsModalGroupClassSelections from "./BookingsModalGroupClassSelections"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`
const Summary = styled.section`
  padding: 40px 15px 15px 36px;
  width: 100%;
  border-bottom: 3px solid rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 700px) {
    padding: 42px calc(var(--content-pad) * 1.5) calc(var(--content-pad) * 1.5);
  }
`
const Main = styled.div`
  position: relative;
  padding: 20px 40px 30px;
  text-align: left;
  flex: 1;
  background: #fff;
  @media screen and (max-width: 700px) {
    padding: calc(var(--content-pad) * 2) calc(var(--content-pad) * 1.5)
      calc(var(--content-pad) * 1.5);
  }
`

const getOnBack = ({
  view,
  tenantInfo,
  selectedId,
  setView,
  hasBookingDescription,
  iframeIndex,
  setIframeIndex
}) => {
  switch (view) {
    case "calendar": {
      if (hasBookingDescription) {
        return () => {
          segmentAction.track(
            `Sparkles Booking Back To Description`,
            { bookingId: selectedId },
            { tenantInfo }
          )
          setView("description")
        }
      } else return
    }
    case "contact": {
      return () => {
        segmentAction.track(
          `Sparkles Booking Back To Calendar`,
          { bookingId: selectedId },
          { tenantInfo }
        )
        setIframeIndex(iframeIndex + 1)
        setView("calendar")
      }
    }
    default:
      return
  }
}

const ignorePaymentInit = false
const voluntaryContributionInit = (selectedBooking) => {
  let price = (selectedBooking || {}).price
  if ((selectedBooking || {}).payment_type === "optional")
    price = Math.ceil(price)
  return price
}
const BookingsModal = ({
  tenantInfo,
  tenantInfo: { tenantId, tenantTimezone },
  location: { search },
  selectedBooking,
  clearBooking,
  timeZone,
  setTimeZone,
  setView,
  view: passedView, // 'first','description', 'calendar', 'contact', 'success', 'error',
  bookingInfo,
  setBookingInfo,
  setRefreshMaxedOutDays,
  timekitHeight,
}) => {
  const shouldUseNylas = tenantInfo && tenantInfo.tenantNylasAccountId;

  const widgetMode = useWidgetModeContext()
  const {
    name,
    email,
    phone,
    description: notes,
    cancelBookingId,
    project,
    resource,
  } = queryString.parse(search) // this is just on first load right???
  const bookingCompleted = () => setRefreshMaxedOutDays(true) // Redone at the end of each booking
  const [isProcessing, setIsProcessing] = useState(false)
  const [pendingPaymentId, setPendingPaymentId] = useState(null) // Held till payment finishes
  const [customerInfo, setCustomer] = useState({
    // TODO: Possibly move to PageBookings?
    // TODO: Put into local storage for returning to a booking from stripe?
    name,
    email,
    phone,
    description: notes ? decodeURIComponent(notes) : "",
    zoom: false,
    project: !cancelBookingId ? project : undefined, // Temp till removed from cancelBookings url
    resource,
    participants: [],
  })

  // Optional payment
  const [voluntaryContribution, setVoluntaryContribution] = useState(
    voluntaryContributionInit(selectedBooking)
  )
  const [ignorePayment, setIgnorePayment] = useState(ignorePaymentInit) // used in optional payments only

  // Nylas modal
  const [iframeIndex, setIframeIndex] = useState(0);

  // Reset customerInfo props when switching bookings
  // Reset pricing as well
  const selectedId = (selectedBooking || {}).id
  const selectedPrice = (selectedBooking || {}).price
  const selectedPaymentType = (selectedBooking || {}).payment_type
  useEffect(() => {
    if (selectedId) {
      setCustomer((prev) => {
        return {
          ...prev,
          // Resetting
          zoom: false,
          description: "",
        }
      })
      setIgnorePayment(ignorePaymentInit)
      setVoluntaryContribution(
        voluntaryContributionInit({
          price: selectedPrice,
          payment_type: selectedPaymentType,
        })
      ) // default to selected booking)
    }
  }, [selectedId, selectedPrice, selectedPaymentType])

  // Auto redirects to stripe for required payments
  // - Once `pendingPaymentId` exists
  useBookingRequiredPaymentToStripe({
    customerInfo,
    pendingPaymentId,
    tenantInfo,
    bookingInfo,
    selectedBooking,
    setIsProcessing,
    voluntaryContribution:
      selectedPaymentType === "optional" && !ignorePayment
        ? voluntaryContribution
        : undefined,
  })

  // Set first view
  // Decide to show description or calendar first
  let view = passedView
  const hasBookingDescription = !!(
    (selectedBooking || {}).description || ""
  ).trim()
  const isGroupClass = !!(
    (selectedBooking || {}).isGroupClass || false
  )
  if (passedView === "first") {
    if (hasBookingDescription) {
      view = "description"
    }
    else if (isGroupClass) {
      view = "group-class-list"
    }
    else {
      view = hasBookingDescription ? "description" : "calendar"
    }
  }

  // Keep modal a standard height... contact info might grow
  // Could use a diff method to ensure same height like: https://codesandbox.io/s/grow-to-the-largest-height-carousel-00sdf
  const contentStyle = {
    height: timekitHeight + timekitHeightPadding, // Prevent scrolling areas that don't need to be there especially on mobile
  }

  const onEditTime = () => {
    segmentAction.track(
      `Sparkles Booking Time Edit`,
      { bookingId: selectedId },
      { tenantInfo }
    )
    setIframeIndex(iframeIndex + 1)
    setView("calendar")
  }
  const hide = !selectedBooking || !view
  const onBack = getOnBack({
    view,
    tenantInfo,
    selectedId,
    setView,
    hasBookingDescription,
    iframeIndex,
    setIframeIndex
  })

  return (
    // For some reason must also hide from this level as well as at the modal level
    // Gatsby was still showing modal even if it shouldn't...
    <div style={hide ? { opacity: 0, pointerEvents: "none" } : {}}>
      <Modals
        onBack={onBack}
        onlyCenterModals={widgetMode} // Do not want "mobile view" to tether to bottom of page
        type={"booking"}
        hide={hide}
        {...(view === "success"
          ? getBookingSuccessProps(clearBooking)
          : {
            onClick: clearBooking,
            onClose: clearBooking,
          })}
      >
        {view === "success" ? (
          <BookingsSuccess
            tenantInfo={tenantInfo}
            onClose={clearBooking}
            customerInfo={customerInfo}
            selectedBooking={selectedBooking}
            bookingInfo={{ ...(bookingInfo || {}), timeZone }}
          />
        ) : (
          <Container>
            <Summary style={{ minHeight: summaryHeight }}>
              <BookingsModalSummary
                timeZone={timeZone || ""}
                selectedBooking={selectedBooking}
                bookingInfo={bookingInfo}
                view={view}
                onEditTime={onEditTime}
              />
            </Summary>
            <Main
              key={`${(selectedBooking || {}).id || 0}`} // Prevents some bugginess with timekit by forcing a bookingsjs rerender... could be view by switching events and then the timezones... then would never fully update
            >
              {/* Carousel may not be needed anymore since we are setting an exact height now. Was used to display each item based on the max of all slides */}
              {/* NOTE: If you do remove carousel remember to always keep timekit rendering around especially the bookingjs id */}
              <CarouselEqualHeight
                items={[
                  // Description
                  {
                    name: "description",
                    elem: (
                      <Content
                        view={view}
                        contentStyle={contentStyle}
                        title={selectedBooking?.name || "Description"}
                      >
                        <BookingsModalDescription
                          setView={setView}
                          selectedBooking={selectedBooking}
                        />
                      </Content>
                    ),
                  },
                  // Error
                  {
                    name: "error",
                    elem: (
                      <Content
                        view={view}
                        title={""}
                        contentStyle={contentStyle}
                      >
                        <PageErrorForm
                          segmentLog={"while trying to book"}
                          sub={
                            "If this is an emergency, please call 651‑349‑4918."
                          }
                          btnText={"Exit"}
                          onClick={clearBooking}
                          style={{ paddingBottom: 30 }}
                        />
                      </Content>
                    ),
                  },
                  // Contact
                  {
                    name: "contact",
                    elem: (
                      <Content
                        view={view}
                        title={"Contact"}
                        contentStyle={contentStyle}
                      >
                        <BookingsModalContact
                          isGroupClass={isGroupClass}
                          tenantInfo={tenantInfo}
                          ignorePayment={ignorePayment}
                          setIgnorePayment={setIgnorePayment}
                          setCustomer={setCustomer}
                          customerInfo={customerInfo}
                          booking={selectedBooking || {}}
                          voluntaryContribution={voluntaryContribution}
                          setVoluntaryContribution={setVoluntaryContribution}
                          onSubmit={() => {
                            onContactSubmit({
                              bookingInfo,
                              ignorePayment,
                              customerInfo,
                              selectedBooking,
                              timeZone,
                              tenantId,
                              bookingCompleted, // TODO: remove? if key is reset will get double check times then
                              setView,
                              setIsProcessing,
                              setPendingPaymentId,
                            });
                            setCustomer({ ...customerInfo, metadata: {} });
                          }

                          }
                          isProcessing={isProcessing}
                        />
                      </Content>
                    ),
                  },
                  // Group Class List
                  {
                    name: "group-class-list",
                    elem: (
                      <Content
                        view={view}
                        // title={"Select Time"}
                        noHeadingPadding
                        contentStyle={contentStyle}
                      >
                        <BookingsModalGroupClassSelections
                          tenantId={tenantId}
                          tenantInfo={tenantInfo}
                          selectedBooking={selectedBooking}
                          timekitHeight={timekitHeight}
                          setView={setView}
                          setBookingInfo={setBookingInfo}
                          setTimeZone={setTimeZone}
                          timezone={tenantTimezone}
                        />
                      </Content>
                    ),
                  },
                  // Calendar
                  {
                    name: "calendar",
                    elem: (
                      <Content
                        view={view}
                        // title={"Select Time"}
                        noHeadingPadding
                        contentStyle={contentStyle}
                      >
                        {shouldUseNylas ?
                          <BookingsModalNylas
                            selectedBooking={selectedBooking}
                            timekitHeight={timekitHeight}
                            setView={setView}
                            setBookingInfo={setBookingInfo}
                            setTimeZone={setTimeZone}
                            timezone={tenantTimezone}
                            iframeIndex={iframeIndex}
                          />
                          :
                          <BookingsModalCalendar />}
                      </Content>
                    ),
                  },
                ]}
                active={view}
              />
            </Main>
          </Container>
        )}
      </Modals>
    </div>
  )
}

export default BookingsModal
