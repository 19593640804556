import React from "react"
import styled from "styled-components"
import TextOverflowGradient from "../../core/TextOverflowGradient"
import TextOverflowReadMore from "../../core/TextOverflowReadMore"
import Linkify from "react-linkify"
import { getArrFromParas } from "../../utils/text"

const Content = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0.4em;
    line-height: 1.5;
    a {
      color: var(--accent);
    }
  }
`

const ProductPageDescriptionText = ({ description }) => {
  if (!description) return null
  const text = (
    <Linkify>
      {getArrFromParas(description).map((t, i) => {
        return <p key={i}>{t}</p>
      })}
    </Linkify>
  )
  return (
    <>
      {/* "desktopOnly" + "mobileOnly" defined in ProductPage container" */}
      {/* Test with a LOT of text :)  */}
      {/* DESKTOP ONLY - scrolling + fades */}
      <Content
        className={"desktopOnly"}
        style={{
          flex: "1 1 0",
          minHeight: "100px", // especially useful as width of screen lessens
        }}
      >
        <TextOverflowGradient>{text}</TextOverflowGradient>
      </Content>
      {/* MOBILE ONLY - click to expand */}
      <Content className={"mobileOnly"}>
        <TextOverflowReadMore maxHeight={90}>{text}</TextOverflowReadMore>
      </Content>
    </>
  )
}

export default ProductPageDescriptionText
