import React, { useEffect } from "react"
import { parseBookingForSuccessAndCancel } from "../sparklePage/utils"
import Linkify from "react-linkify"
import ProductLineItem from "../sparklePage/store/ProductLineItem"
import { LineItemsContainer } from "../sparklePage/store/Cart"
import { Content } from "../sparklePage/bookings/BookingsSharedSuccessAndCancel"
import { segmentAction } from "../hooks/useSegmentTrack"

// So we can use the same modal as bookings but swap in diff props to get the smaller modal... this will prevent the black background from blinking in/out on 2 modals this will use just 1 modal instead
export const getBookingSuccessProps = (onClose) => {
  return {
    type: "prompt",
    primary: {
      children: "Close",
      onClick: onClose,
      style: { width: "100%" },
    },
    onClose,
  }
}

// Free bookings
const BookingsSuccess = ({
  customerInfo: { email, phone } = {},
  bookingInfo,
  tenantInfo,
  applyTimezone = false,
  selectedBooking,
  paid, // came here from a paid modal
  selectedBooking: { additionalConfirmation = "", price } = {},
}) => {
  const text = parseBookingForSuccessAndCancel({
    bookingInfo,
    selectedBooking,
    applyTimezone,
  })
  const bookingId = (selectedBooking || {}).id
  useEffect(() => {
    if (tenantInfo && bookingId) {
      segmentAction.track(
        `Sparkles Booking Success`,
        { bookingId },
        { tenantInfo }
      )
    }
  }, [tenantInfo, bookingId])
  return (
    <Content>
      <h2 style={{ marginBottom: ".5rem" }}>Thank you!</h2>
      <p style={{ marginBottom: "1rem" }}>
        {`You created a successful booking!`}
      </p>
      <LineItemsContainer>
        <ProductLineItem
          quantity={1}
          price={paid ? price : 0}
          itemType={"booking"}
          name={text[0]}
          descriptions={text.slice(1)}
        />
      </LineItemsContainer>
      {additionalConfirmation && (
        <p style={{ marginTop: "1rem" }}>
          <strong>Additional Information:</strong>
          <br />
          <Linkify>{additionalConfirmation}</Linkify>
        </p>
      )}
      <p style={{ marginTop: "1rem" }}>
        <span style={{ fontWeight: "bold" }}>Confirmations sent to:</span>
        <br />
        {email}
        {phone && (
          <>
            <br />
            {phone}
          </>
        )}
      </p>
      <p style={{ marginTop: "1rem" }}>
        <a
          href="https://sprkl.es/"
          onClick={() =>
            segmentAction.track("Confirmation Get Sparkle Click", {
              value: "get sparkle",
            })
          }
          target="_blank"
          rel={"noopener noreferrer"}
          style={{ fontWeight: "bold", textDecoration: "none" }}
        >
          Get your own ✨ Sparkle Site today →
        </a>
      </p>
    </Content>
  )
}

export default BookingsSuccess
