import React, { useState, useEffect } from "react"
import useCartAfterPayment from "../hooks/useCartAfterPayment"
import Modals from "../core/Modals"
import { ButtonPrimary } from "../core/Buttons"
import { Content } from "../core/ModalsShared"
import { parseCost } from "../sparklePage/utils"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import ProductLineItem from "../sparklePage/store/ProductLineItem"
import { segmentAction } from "../hooks/useSegmentTrack"

const HeaderTitle = styled.h4`
  font-weight: bold;
  font-size: 0.8rem;
  margin: 0 0 8px 8px;
  text-align: left;
`
const FooterTitle = styled.h4`
  font-size: 0.9rem;
  margin: 0 0 0px 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`
const Container = styled.div`
  text-align: center;
  margin: 24px 0px 0px;
  font-variant-numeric: tabular-nums;
`
const LineItemsContainer = styled.div`
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: 5px 0 15px;
  margin: 10px 0px;
`

const PurchaseSuccessProduct = ({
  tenantInfo = {},
  tenantUrl,
  productId,
  sessionId,
  errorComp,
}) => {
  const [show, setShow] = useState(true)
  const {
    // cart,
    cart: {
      lineItems: { data = [] } = {},
      inStorePickupEnabled,
      inStorePickupAppointmentType,
      session: { amount_total, customer_email } = {},
    } = {},
    loading,
    error,
  } = useCartAfterPayment({
    tenantId: tenantInfo.tenantId,
    sessionId,
    productId,
    tenantUrl,
  })
  const toHomepage = () => navigate(`/${(tenantUrl || "").toLowerCase()}/`)
  const onClose = () => {
    setShow(false) // is setShow even necessary??? TODO: possibly delete
    toHomepage()
  }
  const itemCount = data.reduce((a, { quantity }) => {
    return a + quantity
  }, 0)

  // Tracking
  useEffect(() => {
    if (!error && show && amount_total && tenantUrl) {
      segmentAction.track("Sparkles Cart Checkout Success", {
        amountTotal: amount_total,
        itemCount,
        tenantUrl,
        sessionId,
        productId,
      })
    }
  }, [error, show, tenantUrl, sessionId, productId, amount_total, itemCount])

  if (error) return errorComp
  if (!show) return null

  const storePickup = inStorePickupEnabled && inStorePickupAppointmentType
  return (
    <Modals
      loadingTitle={"Loading..."}
      loading={loading}
      onClick={onClose}
      onClose={onClose}
      {...(storePickup
        ? {
            // Primary is taken up by the store pickup
            secondary: {
              children: "Close",
              onClick: onClose,
              style: { width: "100%" },
            },
          }
        : {
            primary: {
              children: "Close",
              onClick: onClose,
              style: { width: "100%" },
            },
          })}
    >
      <Content>
        <>
          <h2>Thank you!</h2>
          <p>
            Your order was successful! A receipt has been emailed to{" "}
            <strong>{customer_email}</strong>
          </p>
          <Container>
            <HeaderTitle>Order Info</HeaderTitle>
            <LineItemsContainer>
              {data.map((item, i) => {
                const {
                  quantity,
                  description,
                  price: {
                    unit_amount,
                    product: {
                      metadata: {
                        type,
                        requiresShipping,
                        additionalConfirmation,
                        customerNotes,
                        productId,
                        storePickupEnabled,
                        imageUrl,
                      } = {},
                    } = {},
                  } = {},
                } = item
                return (
                  <ProductLineItem
                    key={i}
                    imageUrl={imageUrl}
                    requiresShipping={requiresShipping}
                    storePickupEnabled={storePickupEnabled}
                    productId={productId}
                    price={unit_amount}
                    itemType={type}
                    quantity={quantity}
                    displayType={"reciept"}
                    additionalConfirmation={additionalConfirmation}
                    customerNotes={customerNotes}
                    name={description}
                  />
                )
              })}
            </LineItemsContainer>
            <FooterTitle>
              <span style={{ fontSize: ".8rem" }}>
                Purchased {`${itemCount} item${itemCount > 1 ? "s" : ""}`}
              </span>
              <span>
                <span style={{ marginRight: 3 }}>Total</span>{" "}
                <strong>${parseCost(amount_total)}</strong>
              </span>
            </FooterTitle>
          </Container>
          {storePickup && (
            <div style={{ marginTop: 28 }}>
              <p style={{ marginBottom: 8, fontWeight: "bold" }}>
                This order has in-store pickup.
              </p>
              <ButtonPrimary
                style={{ width: "100%" }}
                onClick={() =>
                  navigate(
                    `/${(
                      tenantUrl || ""
                    ).toLowerCase()}/?project=${inStorePickupAppointmentType}`
                  )
                }
              >
                Schedule Pickup
              </ButtonPrimary>
            </div>
          )}
        </>
      </Content>
    </Modals>
  )
}

export default PurchaseSuccessProduct
