import React from "react"

const IconBasket = (props) => {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M494.2,193h-57.9l-51.2-77.2c-0.5-0.8-1.2-1.4-1.9-2l-46.6-71.1c-7.6-11.4-23-14.5-34.5-7c-11.3,7.5-14.5,23.2-7,34.5 L376.6,193H135.4l81.5-122.7c7.6-11.4,4.5-26.9-7-34.5c-11.3-7.5-27-4.3-34.5,7L75.7,193H17.8C8,193,0,201,0,210.8v51.1 c0,9.8,8,17.8,17.8,17.8h11.3l32.9,177.6c2.5,13.3,14.1,23,27.7,23h271.5h34.2h27c13.6,0,25.2-9.7,27.7-23l32.9-177.6h11.3 c9.8,0,17.8-8,17.8-17.8v-51.1C512,201,504,193,494.2,193z M307.6,62c-3-4.5-1.7-10.7,2.8-13.6c4.5-3,10.6-1.8,13.6,2.8l72,109.8 c0.7,1,1.5,1.8,2.5,2.3l35.4,53.3c3.2,4.9,1.4,11.7-3.8,14.3c-4.4,2.2-9.9,0.7-12.6-3.4L307.6,62z M78.1,216.6L188,51.1 c3-4.5,9.2-5.7,13.6-2.8c4.5,3,5.8,9.1,2.8,13.6L94.5,227.5c-2.7,4.1-8.3,5.6-12.7,3.3C76.6,228.1,74.9,221.4,78.1,216.6z  M435.3,454.6c-1.1,6.2-6.6,10.7-12.9,10.7h-27h-34.2H89.6c-6.3,0-11.7-4.5-12.9-10.7L44.4,279.7h157c0,0,0,0,0,0h266.2 L435.3,454.6z M497,261.9c0,1.5-1.3,2.8-2.8,2.8H164.8c0,0,0,0,0,0H17.8c-1.5,0-2.8-1.3-2.8-2.8v-51.1c0-1.5,1.3-2.8,2.8-2.8h47.9 l-0.2,0.2c-3.7,5.5-5,12.2-3.7,18.7c1.5,7.4,6.4,13.8,13.1,17.2c11.1,5.7,25.1,1.9,32-8.4l18.4-27.7h261.2l18.4,27.7 c7.5,11.3,23.2,14.5,34.5,7c11.4-7.6,14.5-23,7-34.5l-0.2-0.2h47.9c1.5,0,2.8,1.3,2.8,2.8V261.9z" />
      <path d="M256,428.8c4.1,0,7.5-3.4,7.5-7.5V311.1c0-4.2-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5v110.1 C248.5,425.3,251.9,428.8,256,428.8z" />
      <path d="M187.7,303.6c-4.1,0.3-7.2,4-6.9,8.1l9.3,110.1c0.3,4.1,4,7.2,8.1,6.9c4.1-0.3,7.2-4,6.9-8.1l-9.3-110.1 C195.5,306.4,191.9,303.3,187.7,303.6z" />
      <path d="M124,309.9c-0.7-4.1-4.6-6.9-8.7-6.2c-4.1,0.7-6.9,4.6-6.2,8.7l18.6,110.1c0.7,4.1,4.7,6.9,8.8,6.1c4-0.8,6.7-4.6,6-8.6 L124,309.9z" />
      <path d="M313.7,428.7c4.1,0.3,7.8-2.8,8.1-6.9l9.3-110.1c0.3-4.1-2.7-7.8-6.9-8.1c-4.1-0.3-7.8,2.7-8.1,6.9l-9.3,110.1 C306.5,424.7,309.6,428.4,313.7,428.7z" />
      <path d="M376.8,428.8c3.6,0,6.8-2.6,7.4-6.3l18.6-110.1c0.7-4.1-2.1-8-6.2-8.7c-4.1-0.7-8,2.1-8.7,6.2L369.4,420 C368.6,424.5,372.2,428.8,376.8,428.8z" />
    </svg>
  )
}

export default IconBasket
