import { useEffect, useState } from "react"
import axios from "axios"
import {
  useCartClear,
  getCartSessionLocalStorageKey,
} from "./useCartPrePayment"
import useIsBrowser from "./useIsBrowser"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useCartAfterPayment = ({ tenantId, tenantUrl, productId, sessionId }) => {
  const cartClear = useCartClear()
  const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const isBrowser = useIsBrowser()

  // Clear cart if session id matches local storage
  useEffect(() => {
    let prevSessionId
    if (isBrowser) {
      const sessionKey = getCartSessionLocalStorageKey(tenantId)
      prevSessionId = localStorage.getItem(sessionKey)
    }
    if (sessionId === prevSessionId) {
      cartClear()
    }
  }, [isBrowser, sessionId, cartClear, tenantId])

  // CART / PRODUCT
  useEffect(() => {
    if (tenantId && sessionId) {
      setLoading(true)
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`GetStripeCheckoutSession?code=${apiCode}`, {
          tenantId,
          sessionId,
          ...(productId ? { productId } : {}),
        })
        .then((response) => {
          if (response && response.data) {
            setCart(response.data)
            setLoading(false)
          } else {
            setError(true) // When would this happen?
          }
        })
        .catch((error) => {
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [tenantId, sessionId, productId, tenantUrl])
  return { cart, loading, error }
}

export default useCartAfterPayment
