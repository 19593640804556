import React, { useRef, useState } from "react"
import { useOverflow } from "use-overflow"
import { GradBottom } from "./TextOverflowGradient"

const TextOverflowGradient = ({ children, maxHeight }) => {
  const textRef = useRef(null)
  const { refYOverflowing: overflowY, refYScrollEnd: atBottomOfScroll } =
    useOverflow(textRef)
  const [expand, setExpand] = useState(false) // only for mobile description
  if (!children) return null
  return (
    <button
      onClick={() => {
        setExpand((v) => !v)
      }}
      style={{
        cursor: "pointer",
        overflow: "hidden",
        height: "100%",
        paddingBottom: 0,
        position: "relative",
      }}
    >
      <GradBottom
        style={{
          height: "60px",
          background:
            "linear-gradient(#fff 0%, #fff 25%, rgba(255,255,255,0) 100%)",
          transition: "none",
          opacity: atBottomOfScroll || !overflowY || expand ? 0 : 1,
          transform:
            atBottomOfScroll || !overflowY || expand
              ? "translateY(100%) scaleY(-1)"
              : `translateY(0) scaleY(-1)`,
        }}
      />
      <div
        style={{
          textAlign: "left",
          height: "100%",
          ...(expand
            ? { overflow: "auto" }
            : { maxHeight, overflow: "hidden" }),
        }}
        ref={textRef}
      >
        {children}
      </div>
      {/* Read More/Expand Text */}
      {(overflowY || expand) && (
        <p
          style={{
            bottom: 0,
            right: 0,
            position: "absolute",
            textTransform: "uppercase",
            fontSize: ".7em",
            textAlign: "right",
            fontWeight: "bold",
            color: "#666",
            padding: "6px 0 ",
            lineHeight: 0,
          }}
          className={"mobileOnly"}
        >
          {expand ? "- Hide" : "+ Read More"}
        </p>
      )}
    </button>
  )
}

export default TextOverflowGradient
