import React from "react"
import IconCheck from "../../icons/IconCheck"

const ProductStockText = ({ maxQty }) => {
  if (maxQty <= 0)
    return (
      <span
        style={{
          color: "var(--error)",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Sold Out
      </span>
    )
  if (maxQty < 12)
    return (
      <span>
        Limited quantity. Only <strong>{maxQty} items</strong> left!
      </span>
    )
  else
    return (
      <span
        style={{
          color: "var(--success)",
          paddingLeft: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconCheck style={{ marginBottom: 2 }} />
        <span style={{ marginLeft: 8 }}>{`In stock`}</span>
      </span>
    )
}

export default ProductStockText
