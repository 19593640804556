import React, { useState, useEffect } from "react"
import styled from "styled-components"
import IconLink from "../icons/IconLink"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Popover from "./Popover"

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #ddd;
  transition: 0.15s;
  > * {
    padding: 5px 0px !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    color: currentColor !important;
    padding-right: 1rem !important;
    cursor: pointer;
  }
  :hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const share = [
  {
    Icon: FacebookIcon,
    Button: FacebookShareButton,
    name: "Facebook",
    text: "Share on Facebook",
  },
  {
    Icon: TwitterIcon,
    Button: TwitterShareButton,
    name: "Twitter",
    text: "Share on Twitter",
  },
  { Icon: EmailIcon, Button: EmailShareButton, name: "Email", text: "Email" },
  {
    Icon: LinkedinIcon,
    Button: LinkedinShareButton,
    name: "Linkedin",
    text: "Share on LinkedIn",
  },
  {
    Icon: PinterestIcon,
    Button: PinterestShareButton,
    name: "Pinterest",
    text: "Share on Pinterest",
  },
]

// https://github.com/nygardk/react-share
const defaultOpts = ({ name, media, url }) => ({
  Email: {
    subject: `${name}`, // subject (string): Title of the shared page
    body: `Take a look at this site: \r\r ${name}`, // body (string): Email, will be prepended to the url.
    separator: " \r ", // separator (string, default=" "): Separates body from the url
  },
  Facebook: {
    // Options are a bit weird in FB so ignoring these opts
    // quote: '', // quote (string): A quote to be shared along with the link.
    // hashtag: "#something", // hashtag (string): A hashtag specified by the developer to be added to the shared content. People will still have the opportunity to remove this hashtag in the dialog. The hashtag should include the hash symbol.
  },
  Twitter: {
    title: name, // title (string): Title of the shared page
    // Via works, could put our twitter account or theirs
    // via: "", // via: (string)
    // Hastags don't seem to work
    // hastags: ["#imahash"], // hashtags (array): Hashtags
    // Related doesn't seem to work
    // related: ["@shalanahfaith"], // related (array): Accounts to recommend following
  },
  Pinterest: {
    media, // media(string): REQUIRED An absolute link to the image that will be pinned
    description: name, // description (string): Description for the shared media.
  },
  Linkedin: {
    // Nothing is showing up but the url
    // title: name, // title (string): Title of the shared page
    // summary: "", // summary (string): Description of the shared page
    // source: url, // source (string): Source of the content (e.g. your website or application name)
  },
})

const size = 32
const round = true

const Share = ({
  url: givenUrl, // if not supplied will use current url
  name,
  media,
  target,
  setShow,
  show,
  onOptClick,
}) => {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000)
    }
  }, [copied])

  const url = givenUrl || window.location
  const opts = defaultOpts({ name, media, url })
  return (
    <Popover show={show} setShow={setShow} target={target}>
      <div
        style={{
          fontSize: ".9rem",
          minWidth: 145,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: ".55rem .9rem",
          lineHeight: 0, // for button alignment
        }}
      >
        <div
          style={{
            margin: "0 .4rem",
            fontWeight: "bold",
            textAlign: "left",
            marginBottom: 4,
            width: "100%",
            padding: ".9rem .2rem 1rem",
          }}
        >
          Share
        </div>
        <BtnContainer
          style={copied ? { color: "green" } : undefined}
          onClick={(e) => {
            e.stopPropagation()
            if (onOptClick) onOptClick("copy url")
          }}
        >
          <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
            <div>
              <span style={{ width: size, height: size, display: "flex" }}>
                <IconLink
                  style={{
                    margin: "auto",
                    fill: "currentColor",
                    width: size * 0.45,
                    height: "auto",
                  }}
                />
              </span>
              <span>{copied ? " Copied link ✓" : " Copy link"}</span>
            </div>
          </CopyToClipboard>
        </BtnContainer>
        {share.map(({ Button, Icon, name, text }, i) => {
          if (!media && name === "Pinterest") return null // TODO: could turn this into a func on each instead of this one-off
          return (
            <BtnContainer key={i}>
              <Button
                url={url}
                {...opts[name]}
                onClick={() => {
                  if (onOptClick) onOptClick(text)
                }}
              >
                <Icon
                  size={size}
                  bgStyle={{ fill: "transparent" }}
                  iconFillColor={"currentColor"}
                  round={round}
                />
                <span> {text}</span>
              </Button>
            </BtnContainer>
          )
        })}
      </div>
    </Popover>
  )
}

export default Share
