import { useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"

const apiCode = process.env.GATSBY_SPARKLE_API_CODE
const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise

const stripeRedirect = async (sessionId) => {
  const stripe = await stripePromise
  const result = await stripe.redirectToCheckout({
    sessionId,
  })
  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
}

const useSupportRedirectToStripe = ({
  tenantInfo: { tenantId, tenantPaymentAccounts } = {},
  onProcessing,
  onDone,
  productLineItem,
  productLineItem: { product: { payment_account_id } = {} } = {},
}) => {
  // Load correct stripe account (with correct id)
  useEffect(() => {
    if (tenantPaymentAccounts) {
      // Default stripe id associated with account
      const { id: defaultStripeId } =
        tenantPaymentAccounts.find(({ isDefault }) => isDefault === true) || {}
      let stripeAccount = defaultStripeId || null

      // Possible payment line item stripe id override
      if (payment_account_id) stripeAccount = payment_account_id

      // Set up stripe
      stripePromise = loadStripe(
        `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`,
        {
          stripeAccount,
        }
      )
    }
  }, [payment_account_id, tenantPaymentAccounts])

  if (!tenantPaymentAccounts) return // exit if no pay methods

  // Func or click event to start process
  const onStripeRedirect = async () => {
    if (onProcessing) onProcessing()
    const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
    api
      .post(`CreateStripeCheckoutSession?code=${apiCode}&v=2`, {
        tenantId,
        productLineItem,
      })
      .then((response) => {
        stripeRedirect(response.data.id)
      })
      .catch((error) => {
        console.log(error)
        if (onDone) onDone()
      })
      .finally(() => {})
  }

  return onStripeRedirect
}

export default useSupportRedirectToStripe
