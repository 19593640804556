import React from "react"

const IconTime = (props) => {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        transform="translate(0,-952.36218)"
        d="m 50,970.36218 c -17.6376,0 -32,14.3625 -32,32.00002 0,17.6374 14.3624,32 32,32 17.6376,0 32,-14.3626 32,-32 0,-17.63752 -14.3624,-32.00002 -32,-32.00002 z m 0,6 c 14.3949,0 26,11.6052 26,26.00002 0,14.3947 -11.6051,26 -26,26 -14.3949,0 -26,-11.6053 -26,-26 0,-14.39482 11.6051,-26.00002 26,-26.00002 z m 0,5 c -1.6568,0 -3,1.3432 -3,3 l 0,18.00002 c 0,1.6569 1.3432,3 3,3 l 16,0 c 1.6568,0 3,-1.3431 3,-3 0,-1.6568 -1.3432,-3.00002 -3,-3.00002 l -13,0 0,-15 c 0,-1.6568 -1.3431,-3 -3,-3 z"
      />
    </svg>
  )
}

export default IconTime
