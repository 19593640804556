import React from "react"
import styled from "styled-components"
import ProductImage from "./ProductImage"
import ProductPrice from "./ProductPrice"

const Name = styled.h3`
  font-size: 13.5px;
  margin: 0;
  margin-bottom: 6px;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
`
// Only on product page but not for long
const TenantName = styled.div`
  color: var(--accent);
  font-size: 10.5px;
  font-weight: bold;
  margin-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`
const borderRadius = "10px"
const Box = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  border-radius: ${borderRadius};
  overflow: hidden;
  :before {
    /* Making a square */
    content: "";
    display: block;
    padding-top: 100%;
  }
`
const TextContainer = styled.div`
  padding: 10px 0px 20px 13px;
  flex: 1;
`

const ProductCard = ({
  tenantInfo: { tenantName }, // TODO: might be able to get away with an onClick behavior...
  item: { id: productId, price, name, imageUrl, imageLastModified } = {},
  showTenant = true,
  onClick,
}) => {
  return (
    <>
      <button
        onClick={onClick}
        style={{
          textAlign: "left",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          {/* TODO: get better alt text, have user describe each photo... good for accessibility */}
          <ProductImage
            style={{
              borderRadius, // Pass borderRadius for empty border
            }}
            productId={productId}
            alt={name}
          />
        </Box>
        <TextContainer style={{ display: "flex" }}>
          {/* Temp: May get rid of marketplace at some point */}
          <div style={{ flex: 1 }}>
            {showTenant && <TenantName>{tenantName}</TenantName>}
            <Name>{name}</Name>
            <ProductPrice price={price} />
          </div>
          <div>
            {/* TODO: Turn back on like + social sharing */}
            {/* Turned off like, doesn't work for non-signed-in people... which is most people */}
            {/* <ButtonSocial
              as={"div"}
              style={{
                width: 26,
                height: 26,
              }}
            >
              <ProductLike
                tenantId={tenantId}
                key={isLiked} // needed to reset state :)
                productId={productId}
                user={user}
                isLiked={isLiked}
              />
            </ButtonSocial> */}
          </div>
        </TextContainer>
      </button>
    </>
  )
}

export default ProductCard
