import React from "react"
import styled from "styled-components"
import chroma from "chroma-js"

const Container = styled.div`
  background: transparent;
  border: none !important;
  margin: 0px 0px 0px !important; /* to offset header and align with select a time */
  font-family: Arial !important;
  box-shadow: none !important;
  padding: 0;
  position: relative;
  background: #fff !important;
  .bookingjs-avatar {
    display: none;
  }
  &,
  * {
    font-variant-numeric: tabular-nums !important;
  }
  .bookingjs-error {
    background: none;
  }
  .bookingjs-displayname {
    display: none;
  }
  .fc-button-group {
    display: flex;
  }
  .fc-button-group button.fc-today-button {
    padding: 0px 15px;
    height: auto;
    font-size: 0.8em;
    border-radius: 100px;
    font-weight: bold;
    color: #222 !important;
    transition: 0.3s;
    position: relative;
    background: ${({ theme }) => chroma(theme.colors.accent).alpha(0.15).css()};
    :disabled {
      opacity: 0;
    }
  }
  .bookingjs-footer {
    font-family: Montserrat, sans-serif !important;
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 0px !important;
    background: transparent;
    select {
      cursor: pointer;
      font-family: Montserrat, sans-serif !important;
      font-size: 0.75rem !important;
      letter-spacing: 0.005em;
      line-height: 1;
    }
    @media screen and (max-width: 600px) {
      select {
        max-width: 200px !important;
        font-size: 0.7rem !important;
      }
      margin-bottom: 0px;
    }
    @media screen and (max-width: 380px) {
      select {
        max-width: 180px !important;
      }
    }
    @media screen and (max-width: 325px) {
      select {
        max-width: 130px !important;
      }
    }
  }
  .fc-future {
    background: #fff;
  }
  .bookingjs-footer-tz-picker-arrowdown {
    margin-right: -4px;
    width: 0px;
  }
  .bookingjs-footer-tz * {
    color: #222 !important;
  }
  .bookingjs-footer-tz span {
    line-height: 1 !important;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .bookingjs-footer-tz-picker-select {
    padding: 8px 15px 8px 15px !important;
    background: #efefef !important;
    font-weight: bold;
    border-radius: 100px !important;
    max-width: 300px !important;
    font-size: 14px !important;
  }
  .bookingjs-footer-tz > svg {
    display: none;
  }
  td.fc-day.fc-widget-content.fc-today {
    background-color: ${({ theme }) =>
      chroma(theme.colors.accent).alpha(0.15).css()};
  }
  .fc-day-grid-event .fc-time {
    font-weight: 1.1em;
  }
  .fc .fc-toolbar.fc-header-toolbar,
  .fc-unthemed .fc-basic-view .fc-scroller,
  .fc-scroller.fc-day-grid-container {
    padding: 0 !important;
    margin: 0 !important;
  }
  .bookingjs-ribbon-container {
    display: none;
  }
  .fc-row.fc-widget-header .fc-day-header {
    color: #444 !important;
  }
  .fc-toolbar.fc-header-toolbar {
    min-height: auto !important;
    position: relative;
    height: 42px;
    font-family: Montserrat, sans-serif !important;
  }
  .fc-center {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) !important;
    h2 {
      font-size: 16.5px;
      font-weight: bold;
    }
  }
  .fc-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) !important;
  }
  .bookingjs-loading-icon svg path {
    fill: ${({ theme }) => theme.colors.accent} !important;
  }
  .bookingjs-loading,
  .bookingjs-loading.show {
    background: #fff !important;
  }
  .fc-view {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .fc-row.fc-widget-header table {
    font-family: Montserrat, sans-serif;
  }
  .fc-day-grid-event {
    padding: 8px 8px 8px 10px !important;
    margin: 0px 0px 4px !important;
    font-size: 1rem;
  }
  .fc-event:hover,
  .fc-event-clicked {
    border-left-color: ${({ theme }) => theme.colors.accent} !important;
    color: ${({ theme }) => theme.colors.accent} !important;
  }
  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td,
  .bookingjs-calendar,
  .fc .fc-toolbar {
    border-color: rgba(0, 0, 0, 0.15);
  }
  .fc-day-grid-event .fc-content /* allow for wrapping on text time cells */ {
    white-space: normal !important;
  }
  .bookingjs-footer-tz-picker-arrowdown {
    opacity: 0.8;
  }
  /* LIST VIEW STYLES */
  .fc-unthemed .fc-list-view .fc-scroller {
    padding: 0px !important;
  }
  .fc-list-table {
    margin: 0px;
    width: 100% !important;
    max-width: 100% !important;
    .fc-list-item-marker.fc-widget-content {
      border: none !important;
    }
    .fc-widget-header {
      padding-left: 0px;
      color: #222;
      font-size: 1rem;
      font-weight: bold;
      font-family: Montserrat, sans-serif !important;
    }
  }
  .fc-view.fc-list-view.fc-widget-content {
    border: none !important;
    border-bottom: 1px solid #ccc;
    background: transparent;
  }
  .fc-view-container {
    background: transparent;
  }
  .fc-list-item-time {
    width: 100%;
  }
  .fc-list-item {
    border: none;
    padding-left: 0px;
    color: #222;
    border-radius: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    td {
      margin-left: 0px;
    }
    &:hover {
      border: none;
      color: #222;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
      position: relative;
      z-index: 2;
      @media screen and (min-width: 701px) {
        padding: 0px;
        td {
          padding-left: 0px;
          margin-left: 0px;
        }
      }
    }
  }
  .fc-list-heading-main,
  .fc-list-heading-alt {
    color: ${({ theme }) => theme.colors.accent} !important;
  }
  .fc-list-heading-alt {
    opacity: 1;
  }
  .fc-list-heading + .fc-list-item {
    margin-top: 10px;
  }

  .fc-list-item-time {
    padding: 11px 8px;
    font-size: 0.85rem;
    margin: 0px;
    display: block;
    border: none !important;
    border-radius: 10px;
  }
  .fc-list-heading {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    td.fc-widget-header {
      padding: 15px 0px;
      border-top: none;
    }
  }
  .fc-list-item-marker,
  .fc-list-item-title {
    /* Do not understand why this is a part of the */
    display: none;
  }
`

const BookingsModalCalendar = () => {
  return <Container id="bookingjs" />
}

export default BookingsModalCalendar
