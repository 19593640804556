import React, {useState, useEffect} from "react"
import queryString from "query-string"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby-link"
import Modals from "../core/Modals"
import PageErrorForm from "../sparklePage/PageErrorForm"
import useTenantInfoContext from "../hooks/useTenantInfoContext"
import BookingsModalContact from "../sparklePage/bookings/BookingsModalContact"
import Content from "../sparklePage/bookings/BookingsModalContent"
import CarouselEqualHeight from "../core/CarouselEqualHeight"
import moment from "moment-timezone"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`
const Summary = styled.section`
  padding: 40px 15px 15px 36px;
  width: 100%;
  border-bottom: 3px solid rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 700px) {
    padding: 42px calc(var(--content-pad) * 1.5) calc(var(--content-pad) * 1.5);
  }
`
const Main = styled.div`
  position: relative;
  padding: 20px 40px 30px;
  text-align: left;
  flex: 1;
  background: #fff;
  @media screen and (max-width: 700px) {
    padding: calc(var(--content-pad) * 2) calc(var(--content-pad) * 1.5)
      calc(var(--content-pad) * 1.5);
  }
`

const BookingConfirmation = ({ tenantUrl }) => {
  const location = useLocation()
  const { page_slug, account_id, calendar_id, 
    start_time, 
    end_time, 
    name,
    email,
    phone,
    description: notes,
    cancelBookingId,
    project,
    resource, } = queryString.parse(
    location.search
  )  

  const [customerInfo, setCustomer] = useState({
    // TODO: Possibly move to PageBookings?
    // TODO: Put into local storage for returning to a booking from stripe?
    name,
    email,
    phone,
    description: notes ? decodeURIComponent(notes) : "",
    zoom: false,
    project: !cancelBookingId ? project : undefined, // Temp till removed from cancelBookings url
    resource,
    participants: [],
  })

  // No need to do Load tracking here since we also load their own local page

  const view = "contact";

  useEffect(() => {
    if (window && window.parent) 
    {
      const nylasBookingInfo = { 
        resources: [{ id: calendar_id}], 
        start: moment.unix(start_time), 
        end: moment.unix(end_time),
      }   

      const obj = JSON.parse(JSON.stringify(nylasBookingInfo));

      window.parent.postMessage({
        event_id: 'nylas_contact_information_post',
        data: { nylasBookingInfo: obj }
      }, "*")
      
    }
  }, [])

  useEffect(() => {
    if (window) {
      const isIframe = window.self !== window.top
      if (!isIframe && project && account_id && calendar_id && start_time && end_time) {
        // no parent - redirect
        window.location.href = 
          `${process.env.GATSBY_SPARKLE_PATH}${tenantUrl}?project=${project}&account_id=${account_id}&calendar_id=${calendar_id}&start_time=${start_time}&end_time=${end_time}`; 
      }
    }
    
  }, [project, account_id, calendar_id, start_time, end_time])

  if (account_id && calendar_id && start_time && end_time)
    return (
      <div>
        <Container>           
            <Main
              key={`${0}`} // Prevents some bugginess with timekit by forcing a bookingsjs rerender... could be view by switching events and then the timezones... then would never fully update
            >
              <Content
                view={view}
                title={"Contact"}
                // contentStyle={contentStyle}
              >
                loading...
              </Content>
            </Main>
          </Container>
      </div>
    )

  return null
}

export default BookingConfirmation
