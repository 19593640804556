import React from "react"

const IconLocation = (props) => {
  return (
    <svg viewBox="0 0 2000 2000" {...props}>
      <path d="M1000,1808c-14.2,0-28.4-4.7-40.2-14.2c-5.9-4.8-146.4-118.7-288.5-294.4c-84.1-104-151.3-208.3-199.7-309.9 c-62-130-93.4-256.4-93.4-375.7c0-83.9,16.4-165.3,48.9-242c31.3-74,76.1-140.5,133.2-197.6C617.4,317,683.9,272.2,758,240.9 c76.7-32.4,158.1-48.9,242-48.9s165.3,16.4,242,48.9c74,31.3,140.5,76.1,197.6,133.2c57.1,57.1,101.9,123.6,133.2,197.6 c32.4,76.7,48.9,158.1,48.9,242c0,119.3-31.4,245.7-93.4,375.7c-48.4,101.6-115.6,205.9-199.7,309.9 c-142.1,175.8-282.6,289.7-288.5,294.4C1028.4,1803.3,1014.2,1808,1000,1808z M1000,320c-272.3,0-493.8,221.5-493.8,493.8 c0,229.6,143.9,455.8,264.7,605.2c89.4,110.6,179.1,195.9,229.1,240.6c50.4-45.1,141.1-131.4,230.7-242.6 c120-148.8,263.1-374.3,263.1-603.2C1493.8,541.5,1272.3,320,1000,320z"></path>
      <path d="M1000,1135.8c-43.1,0-85-8.5-124.4-25.1c-38.1-16.1-72.2-39.1-101.6-68.5c-29.3-29.3-52.4-63.5-68.5-101.6 c-16.7-39.4-25.1-81.3-25.1-124.4s8.5-85,25.1-124.4c16.1-38.1,39.1-72.2,68.5-101.6c29.3-29.3,63.5-52.4,101.6-68.5 c39.4-16.7,81.3-25.1,124.4-25.1c43.1,0,85,8.5,124.4,25.1c38.1,16.1,72.2,39.1,101.6,68.5c29.3,29.3,52.4,63.5,68.5,101.6 c16.7,39.4,25.1,81.3,25.1,124.4s-8.5,85-25.1,124.4c-16.1,38.1-39.1,72.2-68.5,101.6c-29.3,29.3-63.5,52.4-101.6,68.5 C1085,1127.4,1043.1,1135.8,1000,1135.8z M1000,624.6c-105.7,0-191.6,86-191.6,191.6s86,191.6,191.6,191.6 c105.7,0,191.6-86,191.6-191.6S1105.7,624.6,1000,624.6z"></path>
    </svg>
  )
}

export default IconLocation
