import React from "react"

const IconFile = ({ style = {}, ...props }) => {
  return (
    <svg
      viewBox="0 0 257 308"
      style={{ overflow: "visible", ...style }}
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M256 113H256.5C256.5 112.724 256.276 112.5 256 112.5V113ZM147 1H147.5C147.5 0.723858 147.276 0.5 147 0.5V1ZM1.5 57C1.5 26.3482 26.3482 1.5 57 1.5V0.5C25.7959 0.5 0.5 25.7959 0.5 57H1.5ZM1.5 251V57H0.5V251H1.5ZM57 306.5C26.3482 306.5 1.5 281.652 1.5 251H0.5C0.5 282.204 25.7959 307.5 57 307.5V306.5ZM200 306.5H57V307.5H200V306.5ZM255.5 251C255.5 281.652 230.652 306.5 200 306.5V307.5C231.204 307.5 256.5 282.204 256.5 251H255.5ZM255.5 113V251H256.5V113H255.5ZM256 112.5H203V113.5H256V112.5ZM203 112.5C172.348 112.5 147.5 87.6518 147.5 57H146.5C146.5 88.2041 171.796 113.5 203 113.5V112.5ZM147.5 57V1H146.5V57H147.5ZM57 1.5H147V0.5H57V1.5Z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fill="none"
        d="M147 1L256 113"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconFile
