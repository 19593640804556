import { useLocation } from "@reach/router"
import isEmpty from "lodash/isEmpty"
import React from "react"
import styled from "styled-components"
import { ButtonLinks, ButtonPrimary } from "../core/Buttons"
import { segmentAction, useSegmentTrackLoad } from "../hooks/useSegmentTrack"
import useTenantInfoContext from "../hooks/useTenantInfoContext"
import useTenantSignedInContext from "../hooks/useTenantSignedInContext"
import useWidgetModeContext from "../hooks/useWidgetModeContext"
import { parseLinks } from "../icons/IconLinks"
import Downloads from "./downloads/Downloads"
import { Content } from "./layout/Content"
import PageBookings from "./PageBookings"
import PageFollowShare from "./PageFollowShare"
import PageHeader from "./PageHeader"
import PageShop from "./PageShop"
import Support from "./support/Support"
import { getShowBookings } from "./utils"

const EmptyContent = styled(Content)`
  margin-top: 3rem;
  * {
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  h2 {
    font-weight: bold;
    font-size: 2rem;
  }
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  a {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    text-align: center !important;
    * {
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    h2 {
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    p {
      margin-bottom: 1rem;
    }
    p,
    a {
      font-size: 1rem;
    }
  }
`
const Section = styled.section`
  min-height: 50vh;
  padding: 0rem 0rem 16rem;
`
const PageSectionTitle = styled.h2`
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;
  margin-top: 3.5rem;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
  }
`
const PageSectionTitleCenter = styled(PageSectionTitle)`
  text-align: center;
  margin-bottom: 2rem;
`

// Inspired by https://stackoverflow.com/a/11300963/2824643
const getValidUrl = (url = "") => {
  let next = url.trim() // remove white space + lowercase

  // Check if begins with https:// or http:// or // -- all acceptable also case insensitive with "i"
  const pattern = new RegExp("^(https?://|//)", "gi")
  const res = pattern.test(next)
  return res ? next : "//" + next
}

const Page = ({ tenantUrl, setOverrideAccent }) => {
  // TODO: Make anchor links for each section instead?
  const tenantInfo = useTenantInfoContext()
  const { user, tenantSignedIn, onOwnSite, adminUrl } =
    useTenantSignedInContext()
  const location = useLocation()
  const [socialLinks, regLinks] = parseLinks(tenantInfo?.tenantAdditionalLinks)
  const widgetMode = useWidgetModeContext()
  const numDownloads = (tenantInfo.tenantSendFileActions || []).length

  if (tenantInfo && tenantInfo.tenantThemeAccent) {
    setOverrideAccent(tenantInfo.tenantThemeAccent)
  }

  useSegmentTrackLoad(
    "Sparkles",
    tenantUrl,
    {
      tenantWidgetMode: widgetMode,
      tenantName: tenantInfo.tenantName,
      tenantUrl,
    } // saving
  )

  // Exit early with just booking stuff (widgetMode)
  if (widgetMode)
    return (
      <PageBookings
        tenantInfo={tenantInfo}
        location={location}
        BookingsGridContainer={({ children }) => (
          <Content style={{ padding: "15px 10px" }}>{children}</Content>
        )}
      />
    )

  // TODO: Each time signed in could point them to do more
  const hasBookings = getShowBookings({ tenantInfo })
  const hasProducts = (tenantInfo?.tenantProducts || []).length > 0
  const hasLinksOrDownloads =
    regLinks.length > 0 || (tenantInfo.tenantSendFileActions || []).length > 0
  const looksEmpty = !hasBookings && !hasProducts && !hasLinksOrDownloads

  const hasOnlyLinks = !hasBookings && !hasProducts && !numDownloads

  // Doesn't make the site look less empty so doesn't go into "looksEmpty"
  const hasSupport =
    tenantInfo.tenantContributionProduct &&
    tenantInfo.tenantContributionProduct.id

  return (
    <>
      <PageHeader
        tenantUrl={tenantUrl}
        tenantInfo={tenantInfo}
        socialLinks={socialLinks}
      />
      <Section>
        {looksEmpty && onOwnSite && !isEmpty(tenantSignedIn) && (
          <EmptyContent style={{ padding: "0 var(--content-pad)" }}>
            <h2>Congrats! 🎉</h2>
            <h3>You created a site!</h3>
            <p>Let's make it sparkle! ✨</p>
            <div>
              <ButtonPrimary
                as={"a"}
                href={adminUrl}
                onClick={() =>
                  segmentAction.track("Sparkles Body Settings Click")
                }
              >
                Settings →
              </ButtonPrimary>
            </div>
          </EmptyContent>
        )}
        <PageBookings
          tenantInfo={tenantInfo}
          location={location}
          BookingsGridContainer={({ children }) => (
            <Content>
              <PageSectionTitle>Bookings</PageSectionTitle>
              {children}
            </Content>
          )}
        />
        {/* PRODUCTS */}
        {hasProducts && (
          <Content>
            <PageSectionTitle>Shop</PageSectionTitle>
            <PageShop tenantInfo={tenantInfo} user={user} location={location} />
          </Content>
        )}
        {hasLinksOrDownloads && (
          <Content style={{ padding: "0 var(--content-pad)" }}>
            {/* DOWNLOADS */}
            {numDownloads > 0 && (
              <>
                <PageSectionTitleCenter>Downloads</PageSectionTitleCenter>
                <Downloads
                  tenantId={tenantInfo.tenantId}
                  tenantSendFileActions={tenantInfo.tenantSendFileActions}
                />
              </>
            )}
            {/* LINKS */}
            {regLinks.length > 0 && (
              <>
                <PageSectionTitleCenter>
                  {hasOnlyLinks ? " " : "Links"}
                </PageSectionTitleCenter>
                {regLinks
                  .sort((a, b) => a.order - b.order)
                  .map(({ url, name }, i) => {
                    const validUrl = getValidUrl(url)
                    return (
                      <ButtonLinks
                        onClick={() =>
                          segmentAction.track("Sparkle Link Clicked", {
                            url,
                            validUrl,
                          })
                        }
                        style={{ marginBottom: 9 }}
                        key={i}
                        as={"a"}
                        href={validUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {name}
                      </ButtonLinks>
                    )
                  })}
              </>
            )}
          </Content>
        )}
        {/* SOCIAL @ bottom, only show if we have some other content too */}
        {!looksEmpty && (
          <Content
            style={{
              padding: "0 var(--content-pad)",
              textAlign: "center",
            }}
          >
            <PageSectionTitleCenter>
              {tenantInfo?.tenantName}
            </PageSectionTitleCenter>
            <PageFollowShare
              socialLinks={socialLinks}
              tenantUrl={tenantUrl}
              tenantName={tenantInfo?.tenantName}
              tenantImageUrl={tenantInfo?.tenantImageUrl}
            />
          </Content>
        )}
      </Section>
      {hasSupport && <Support tenantInfo={tenantInfo} />}
    </>
  )
}

export default Page
