import React, { useState } from "react"
import { ButtonPrimary, ButtonSupport as Button } from "../../core/Buttons"
import Modals from "../../core/Modals"
import styled from "styled-components"
import isEmail from "validator/lib/isEmail"
import useSupportRedirectToStripe from "../../hooks/useSupportRedirectToStripe"
import ButtonLoaderWithText from "../../core/ButtonLoaderWithText"
import useQueryParam from "../../hooks/useQueryParam"
import { navigate } from "gatsby-link"
import { segmentAction } from "../../hooks/useSegmentTrack"

export const Content = styled.div`
  h3 {
    margin-bottom: 10px;
    font-size: 1.02rem;
    font-weight: bold;
    line-height: 1.2;
  }
  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.45rem;
    font-weight: bold;
    line-height: 1.2;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.45;
    margin-bottom: 1.25rem;
  }
`

export const Submit = styled(ButtonPrimary)`
  border: 4px solid transparent; // to match other btns
  width: 100%;
  display: block;
  box-shadow: none;
  margin-top: 17px;
  font-variant-numeric: tabular-nums; // for showing prices and it not jumping too much around
  :focus {
    outline: none;
  }
  :focus-visible {
    border-color: #000;
  }
`

const CustomPriceInput = styled.input`
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  font-size: 1rem;
  max-width: 100%;
  color: inherit;
  padding: 0px;
  margin: 0px;
  margin-left: 3px;
  text-align: left;
  ::placeholder {
    text-align: center;
    font-weight: normal;
    color: #999;
  }
`
export const EmailInput = styled(Button)`
  cursor: text;
  font-weight: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  border-color: transparent;
  font-size: 1rem;
  color: #000;
  background: rgba(0, 0, 0, 0.08);
  box-shadow: none;
  ::placeholder {
    color: #000;
    letter-spacing: 0.03em;
  }
  :hover {
    border-color: transparent;
  }
`

const Support = ({ tenantInfo }) => {
  const {
    tenantName,
    tenantUrl,
    tenantContributionProduct: product = {},
    tenantContributionProduct: { description = "", name = "Support" } = {},
  } = tenantInfo || {}
  const loading = !tenantName
  const onClose = () => {
    navigate(`/${tenantUrl}`)
  }
  const show = useQueryParam("support") !== null
  const [isProcessing, setIsProcessing] = useState(false)
  const [price, setPrice] = useState(25)
  const [email, setEmail] = useState("")
  const onSubmit = useSupportRedirectToStripe({
    tenantInfo,
    onProcessing: () => setIsProcessing(true),
    productLineItem: {
      product: { ...product, price: price || product.price },
      quantity: 1,
      email,
    },
  })
  const [custom, setCustom] = useState(false)

  // Errors
  const min = 1 // price
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const emailMissing = !isEmail(email || "")
  const priceNotValid = custom && (!price || price < 1 || isNaN(price))
  let customClasses = []
  if (custom) {
    customClasses.push("active")
    if (clickedSubmit && priceNotValid) customClasses.push("error")
  }
  if (!show) return null

  const descriptionText =
    description.trim() || "Your generosity + kindness goes a long way!"
  return (
    <Modals onClose={onClose} onClick={onClose} loading={loading}>
      <Content
        as={"form"}
        onSubmit={(e) => {
          e.preventDefault()
          if (priceNotValid || emailMissing) {
            // Show errors
            setClickedSubmit(true)
          } else {
            // Continue to Stripe
            onSubmit()
          }
        }}
      >
        {/* Sometimes user puts empty spaces */}
        <h3>{name.trim() || "Support"}</h3>
        <h2>{tenantName}</h2>
        <p style={descriptionText.length > 160 ? { textAlign: "left" } : {}}>
          {descriptionText}
        </p>
        {clickedSubmit && (emailMissing || priceNotValid) && (
          <p className={"error-text"}>
            {emailMissing && `Provide valid email`}
            {emailMissing && priceNotValid && <br />}
            {priceNotValid && `Select an amount of at least $${min}`}
          </p>
        )}
        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(1, minmax(0, 1fr)",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr)",
            marginBottom: 17,
            marginTop: 17,
            gap: 8,
          }}
        >
          {[10, 25, 50, 100, 250].map((v) => {
            return (
              <Button
                type="button"
                onClick={() => {
                  segmentAction.track("Sparkles Support Price Change", {
                    value: v,
                  })
                  setCustom(false)
                  setPrice(v)
                }}
                key={v}
                style={{ flex: 1 }}
                className={!custom && v === price ? "active" : ""}
              >
                ${v}
              </Button>
            )
          })}
          <Button
            type="button"
            className={customClasses.join(" ")}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 6,
              paddingRight: 15,
            }}
          >
            <span>$</span>
            <CustomPriceInput
              min={min}
              onPointerDown={(e) => {
                const val = e.target.value
                setCustom(true)
                setPrice(val)
                segmentAction.track("Sparkles Support Price Custom")
              }}
              onChange={(e) => {
                const val = e.target.value
                setCustom(true)
                setPrice(val)
              }}
              placeholder={"Custom"}
              type={"number"}
            />
          </Button>
        </div>
        {/* TODO: Remove need for email input - user should do this via stripe, we don't want them to have extra steps preventing them from clicking support. */}
        <EmailInput
          disabled={isProcessing}
          as={"input"}
          style={{
            width: "100%",
          }}
          defaultValue={email}
          className={emailMissing && clickedSubmit ? "error" : ""}
          required
          placeholder="Email"
          type={"email"}
          name={"email"}
          onChange={(e) => {
            const val = e.target.value
            setEmail(val)
          }}
        />
        <Submit
          className={isProcessing ? "loading" : ""}
          onClick={() => {
            segmentAction.track("Sparkles Support Submit", { value: price })
            setClickedSubmit(true)
          }}
          type={"submit"}
        >
          <>
            {isProcessing
              ? "To Checkout"
              : `Support${price ? ` $${price}` : ""}`}
            <ButtonLoaderWithText isProcessing={isProcessing} />
          </>
        </Submit>
      </Content>
    </Modals>
  )
}

export default Support
