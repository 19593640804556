import React, { useEffect, useState } from "react"
import axios from "axios"
import queryString from "query-string"
import { getShowBookings, clearBooking, getSelectedBooking } from "./utils"
import useTimekitWidgetSet from "../hooks/useTimekitWidgetSet"
import Bookings from "./bookings/Bookings"
import BookingsCancel from "./bookings/BookingsCancel"
import BookingsModal from "./bookings/BookingsModal"

const PageBookings = ({
  location,
  tenantInfo,
  BookingsGridContainer, // Allowing for diffs between widgetMode + normal mode
}) => {
  const { project, cancelBookingId } = queryString.parse(
    location.search
  )

  const [selectedBooking, setSelectedBooking] = useState()

  const showBookings = getShowBookings({ tenantInfo })
  const {
    timeZone,
    bookingInfo,
    timekitHeight,
    view,
    setBookingInfo,
    setRefreshMaxedOutDays,
    setView,
    setTimeZone,
  } = useTimekitWidgetSet({
    selectedBookingId: (selectedBooking || {}).id,
    tenantInfo,
  })

  const { tenantId } = tenantInfo
  
  useEffect(() => {
    setSelectedBooking(
      getSelectedBooking({
        tenantInfo,
        bookingId: !cancelBookingId ? project : undefined, // Temp till removed from cancelBookings url
      })
    )
  }, [tenantId, project, tenantInfo])

  return (
    <>
      {/* Bookings Cards */}
      {showBookings && (
        <BookingsGridContainer>
          <Bookings tenantInfo={tenantInfo} setView={setView}/>
        </BookingsGridContainer>
      )}
      {/* Bookings Make (Modal) */}
      <BookingsModal
        setView={setView}
        view={view}
        tenantInfo={tenantInfo}
        location={location}
        clearBooking={() => {
          clearBooking()
          setView()
        }}
        selectedBooking={selectedBooking}
        timeZone={timeZone}
        setTimeZone={setTimeZone}
        bookingInfo={bookingInfo}
        timekitHeight={timekitHeight}
        setBookingInfo={setBookingInfo}
        setRefreshMaxedOutDays={setRefreshMaxedOutDays}
      />
      {/* Bookings Cancel (Modal) */}
      <BookingsCancel location={location} tenantInfo={tenantInfo} />
    </>
  )
}

export default PageBookings
