import React from "react"

const IconShare = (props) => {
  return (
    <svg viewBox="0 0 80 60" {...props}>
      <path d="M50,20A51.24,51.24,0,0,0,.3,58.8L0,60A68.35,68.35,0,0,1,48.3,40H50V60L80,30,50,0Z" />
    </svg>
  )
}

export default IconShare
