import React from "react"
import styled from "styled-components"
import { ButtonSuperSimple } from "../../core/Buttons"

const SelectDownArrow = styled.span`
  transform: translateY(-15%) rotate(-45deg);
  margin-left: 0.5em;
  width: 0.4em;
  height: 0.4em;
  border-left: 0.15em solid #999;
  border-bottom: 0.15em solid #999;
`
const LabelText = styled.div`
  letter-spacing: 0.03em;
  font-weight: bold;
  margin-top: 1rem;
  text-transform: uppercase;
  margin: 0;
  display: inline;
  letter-spacing: 0.05em;
  margin-right: 0.3em;
`
const QuantityText = styled.span`
  width: 2ch;
  letter-spacing: 0.07em;
  text-align: center;
  font-weight: bold;
`
const HideSelect = styled.select`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const ProductQuantitySelect = ({
  onChange,
  qty,
  maxQty,
  label = "Quantity",
  allowForZero = false,
  style = {},
}) => {
  return (
    <>
      <ButtonSuperSimple
        as={"div"}
        style={{
          paddingTop: ".75em",
          paddingBottom: ".75em",
          lineHeight: 1.1,
          fontSize: "15.5px",
          position: "relative",
          display: "inline-flex",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <LabelText>{label}</LabelText>
          <QuantityText>{qty}</QuantityText>
          <SelectDownArrow />
        </div>
        <HideSelect
          value={qty}
          onChange={(e) => {
            e.stopPropagation()
            const val = Number(e.target.value)
            onChange(val)
          }}
        >
          {[...Array(maxQty + (allowForZero ? 1 : 0)).keys()].map((i) => {
            const v = i + (allowForZero ? 0 : 1)
            return (
              <option key={i} value={v}>
                {v === 0 ? `0 (Delete)` : v}
              </option>
            )
          })}
        </HideSelect>
      </ButtonSuperSimple>
    </>
  )
}

export default ProductQuantitySelect
