import React, { useState } from "react"
import axios from "axios"
import DownloadsFile from "./DownloadsFile"
const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const API_CODE = process.env.GATSBY_SPARKLE_API_CODE

const sendFileEmail = ({ fileId, tenantId, email, onSuccess }) => {
  if (fileId) {
    // TODO: Also send link to email? Maybe?
    const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
    api
      .post(`HandleNewSendFileRequest?code=${API_CODE}`, {
        tenantId,
        sendFileActionId: fileId,
        customerName: "",
        customerEmail: email,
      })
      .finally(() => {
        // TODO: handle error?
        if (onSuccess) onSuccess() // Not being utilized right now
      })
  }
}

const Downloads = ({ tenantId, tenantSendFileActions }) => {
  const [selected, setSelected] = useState(null)
  const [email, setEmail] = useState("")
  const onCancel = () => {
    setSelected(null)
  }
  // Keep track if we have sent anything... allow for direct download after that.
  return (
    <>
      {tenantSendFileActions
        .sort((a, b) => a.order - b.order)
        .map((data, i) => {
          const { file_url, name } = data
          // Turn off extension for now... user points to a url not an uploaded file
          // const ext = file_url.split(".").slice(-1)
          return (
            <DownloadsFile
              // ext={ext}
              file={file_url}
              text={name}
              key={"accord" + i}
              open={!!selected && data.id === selected.id}
              onCancel={onCancel}
              email={email}
              setEmail={setEmail}
              onOpen={() => setSelected(data)}
              placeholder={"example@example.com"}
              onEmailSubmit={() => {
                sendFileEmail({
                  fileId: data.id,
                  tenantId,
                  email,
                })
              }}
            />
          )
        })}
    </>
  )
}

export default Downloads
