import { useEffect, useState } from "react"
import axios from "axios"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE
const noQtyUpperLimit = 30 // doing this for now

const getQty = (qty) => {
  if (qty === null || qty === undefined) return noQtyUpperLimit
  return Math.max(qty, 0)
}

const useProductMaxQty = ({ quantityAvailable, productId, tenantId }) => {
  const [maxQty, setMaxQty] = useState(getQty(quantityAvailable)) // double check to handle all cases
  useEffect(() => {
    if (productId && tenantId) {
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`GetProductQuantityAvailable?code=${apiCode}`, {
          tenantId,
          productId,
        })
        .then((response) => {
          const product = response.data
          if (!product) {
            console.error("No product returned")
            return
          }
          setMaxQty(getQty(product.quantityAvailable))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [productId, tenantId])
  return maxQty
}

export default useProductMaxQty
