import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import BookingsCard from "./BookingsCard"
import { showOnlyPublic } from "../utils"
import PageSectionGridContainer from "../PageSectionGridContainer"
import { segmentAction } from "../../hooks/useSegmentTrack"
import IconLoaderCircle from "../../icons/IconLoaderCircle"

const Bookings = ({
  tenantInfo,
  tenantInfo: {
    tenantAppointmentTypes: individual = [],
    tenantGroupClasses: group = [],
  },
  setView,
}) => {
  const [availIndividual, setAvailIndividual] = useState([])
  const [availGroup, setAvailGroup] = useState([])

  useEffect(() => {
    setAvailIndividual(individual.filter(showOnlyPublic))
    setAvailGroup(group.filter(showOnlyPublic))
  }, [])

  const getNavigationUrl = (booking) => {
    return `?project=${booking.id}#bookings`
  }

  return (
    <PageSectionGridContainer>
      {[
        { bookings: availIndividual, type: "Individual" },
        { bookings: availGroup, type: "Group" },
      ].map(({ bookings, type }) => {
        return bookings
          .sort((a, b) => a.order - b.order)
          .map((booking, i) => {
            return (
              <BookingsCard
                key={i}
                booking={{
                  ...booking,
                  type,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  segmentAction.track(
                    `Sparkles Booking Click`,
                    {
                      bookingId: booking.id,
                    },
                    { tenantInfo }
                  )
                  navigate(getNavigationUrl(booking))
                  setView("first")
                }}
              />
            )
          })
      })}
    </PageSectionGridContainer>
  )
}

export default Bookings
