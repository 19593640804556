import React, { useEffect } from "react"
import styled from "styled-components"
import { ButtonPrimary } from "../core/Buttons"
import { segmentAction } from "../hooks/useSegmentTrack"
import IconError from "../icons/IconError"

const Center = styled.div`
  margin: auto;
`
const Btn = styled(ButtonPrimary)`
  margin-top: 2rem;
  font-size: 1.1rem;
  min-width: 200px;
`
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
  h3 {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0.25rem;
  }
  h4 {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.3rem;
  }
`

// Previous Text
// Ouch, we've encountered a problem
// An error occurred. Our support team has been notified.

const PageErrorForm = ({ btnText, onClick, segmentLog, sub, ...props }) => {
  useEffect(() => {
    if (segmentLog) {
      segmentAction.track("Sparkles Error Show", { value: segmentLog })
    }
  }, [segmentLog])
  return (
    <Container {...props}>
      <Center>
        <IconError
          style={{
            width: 64,
            height: "auto",
            marginBottom: 5,
            marginTop: 10,
            fill: "currentColor",
          }}
        />
        <h3>Ooops!</h3>
        <h4>Something went wrong.</h4>
        <p>Support has been notified.</p>
        <p>We are sorry for any inconvenience.</p>
        <p style={{ marginBottom: 0 }}>Please try again later.</p>
        {btnText && onClick && <Btn onClick={onClick}>{btnText}</Btn>}
        {sub && <div style={{ marginTop: 15, fontSize: ".9rem" }}>{sub}</div>}
      </Center>
    </Container>
  )
}

export default PageErrorForm
