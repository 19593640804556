import React from "react"
import IconEdit from "../../icons/IconEdit"
import BookingsText from "./BookingsText"
import styled, { keyframes } from "styled-components"
import { parseTimeForBookingsSummary, capitalizeFirstLetter } from "../utils"
import "moment-timezone"

export const fadeInAndUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(3px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateZ(0);
  } 
`

const Container = styled.div`
  display: flex;
  text-align: left;
  height: 100%;
  line-height: 1.2;
  font-weight: normal;
  font-size: 0.8rem;
  button {
    font-size: 0.8rem;
    /* Added specifically for iOS/Safari */
  }
  h4 {
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 11.5px !important;
    letter-spacing: 0.02em;
    margin-bottom: 0.3rem;
  }
  h3,
  p {
    margin-bottom: 0.2rem;
  }
  .animate-in {
    animation: ${fadeInAndUp} 0.3s;
  }
`

const BookingsModalSummary = ({
  timeZone,
  selectedBooking,
  bookingInfo,
  onEditTime,
  view,
}) => {
  const { date, time, fromNow } =
    parseTimeForBookingsSummary(bookingInfo || {}) || {}
  if (!selectedBooking) return null
  const timezoneAbbr =
    timeZone && bookingInfo && bookingInfo.start
      ? bookingInfo.start.tz(timeZone).format("z")
      : ""
  const showTimeslot =
    bookingInfo && view !== "calendar" && view !== "description" && view !== "group-class-list"
  return (
    <>
      <Container>
        <div style={{ maxWidth: 160 }} className={"animate-in"}>
          <BookingsText summary booking={selectedBooking} />
        </div>
        {/* Timeslot info */}
        {/* NOTE: Rendering this even if timeslot isn't yet selected for less vertical jank - esp. since this is usually longer than the booking text */}
        <div
          className={"animate-in"}
          style={{
            opacity: showTimeslot ? 1 : 0,
            transform: showTimeslot ? "translateY(0)" : 0,
            pointerEvents: showTimeslot ? "" : "none",
            animation: showTimeslot ? "" : "none",
            marginLeft: 18,
            paddingLeft: 18,
          }}
        >
          <div>
            <h4>{date}</h4>
            <p style={{ marginTop: 3 }}>
              {time} {timezoneAbbr}
            </p>
            <p>{capitalizeFirstLetter(fromNow)}</p>
            <button
              onClick={onEditTime}
              style={{ textAlign: "left", color: "inherit" }}
            >
              Edit timeslot
              <IconEdit
                style={{
                  fill: "var(--accent)",
                  display: "inline-block",
                  width: "auto",
                  height: ".8em",
                  marginLeft: 5,
                }}
              />
            </button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default BookingsModalSummary
