import React, { useState } from "react"
import { Router } from "@reach/router"
import queryString from "query-string"
import Page from "../sparklePage/Page"
import Seo from "../core/Seo"
import Layout from "../sparklePage/layout/Layout"
import PurchaseSuccess from "../sparkleSuccess/PurchaseSuccess"
import BookingConfirmation from "../sparkleSuccess/BookingConfirmation"
import { WidgetModeProvider } from "../hooks/useWidgetModeContext"
import { CartProvider } from "../hooks/useCartPrePayment"
import { TenantInfoProvider } from "../hooks/useTenantInfoContext"
import { TenantSignedInProvider } from "../hooks/useTenantSignedInContext"

const App = ({
  pageContext: { tenant } = {},
  pageContext: {
    tenant: {
      tenantName: name,
      tenantUrl: url = "",
      tenantImageUrl: imgUrl,
      tenantProducts: products,
      tenantThemeAccent: themeAccent = "",
    } = {},
  } = {},
  location = {},
  location: { pathname = "" },
}) => {
  const [ overrideAccent, setOverrideAccent ] = useState(null);
  const { productId } = queryString.parse(location.search)

  const path = pathname.split("/")[1] || "" // backup if we don't have a tenantUrl ie wE-SpArKlE
  const tenantUrl = url || path.toLowerCase() // handling lowercase

  // SEO
  const metaUrl = `${process.env.GATSBY_SPARKLE_PATH}${tenantUrl}`
  let metaOgImage = imgUrl || "https://i.imgur.com/AOj9NKFl.png"
  // Set og:image to product image if in queryString
  if (productId && products) {
    const { imageUrl } = products.find(({ id }) => id === productId) || {}
    if (imageUrl) metaOgImage = imageUrl
  }
  return (
    <TenantInfoProvider tenant={tenant} tenantUrl={tenantUrl}>
      <TenantSignedInProvider>
        <CartProvider>
          <WidgetModeProvider location={location}>
            <Layout
              showCart={products && products.length > 0}
              accent={overrideAccent || themeAccent || "#3790E2"}
            >
              <Seo
                title={name ? name : "Sprkles Site"}
                ogImage={metaOgImage}
                ogUrl={metaUrl}
              />
              <Router>
                {/* Booking Confirmation*/}
                <BookingConfirmation
                  path="/:tenantUrl/confirmation"
                  key={tenantUrl + "-confirmation"} // To re-render make segment load event fire
                />
                <Page
                  path="/:tenantUrl/*"
                  key={tenantUrl} // To re-render and make segment load event fire
                  setOverrideAccent={setOverrideAccent}
                />
              </Router>
              {/* Temp solution... couldn't get redirect to work out of box... kept looking for a "page" instead of a route */}
              <Router>
                {/* Purchase success*/}
                <PurchaseSuccess
                  path="/:tenantUrl/checkout"
                  key={tenantUrl + "-checkout"} // To re-render make segment load event fire
                />
              </Router>              
            </Layout>
          </WidgetModeProvider>
        </CartProvider>
      </TenantSignedInProvider>
    </TenantInfoProvider>
  )
}

export default App
