import React, { useState } from "react"
import { ButtonSuperSimple } from "../../core/Buttons"
import IconClose from "../../icons/IconClose"

const ProductLineItemDeleted = ({ product: { name }, onUndo }) => {
  const [hide, setHide] = useState(false)
  if (hide) return null
  return (
    <div
      style={{
        marginTop: "12px",
        background: "#f7f7f7",
        padding: "10px 15px",
        borderRadius: "10px",
        fontSize: 12,
        display: "flex",
        alignItems: "center",
      }}
    >
      <span style={{ flex: 1 }}>Removed {name}</span>
      <ButtonSuperSimple
        style={{ fontSize: "11px", height: "auto", marginLeft: 10 }}
        onClick={onUndo}
      >
        Undo
      </ButtonSuperSimple>
      <button
        onClick={() => setHide(true)}
        style={{ padding: "5px", marginLeft: 10 }}
      >
        <IconClose
          length={2}
          style={{
            width: 10,
            height: 10,

            color: "#999",
          }}
        />
      </button>
    </div>
  )
}

export default ProductLineItemDeleted
